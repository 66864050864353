<template>
    <div class="invoice-table--row row-break" :class="{'border-none': index === (products.length - 1)}">
        <div class="invoice-table--col col-center w-1/16">
            <div class="col-position">
                <div class="invoice-table--row-position">
                    <feather-icon icon="ChevronUpIcon" svgClasses="position-up"
                                  @click="moveProduct(-1)"/>
                    <feather-icon icon="ChevronDownIcon" svgClasses="position-down"
                                  @click="moveProduct(1)"/>
                </div>
                {{index+1}}
            </div>
        </div>

        <div class="flex-1 invoice-table--col col-break">
            <div class="break-text"><span>Seitenumbruch</span></div>
        </div>

        <div class="invoice-table--row-delete" @click="removeProduct()">
            <feather-icon icon="DeleteIcon" class="w-6 h-6"/>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            index: {
                type: Number
            },
            products: {
                type: Array
            },
            product: {
                type: Object
            }
        },
        name: "RowBreak",
        methods: {
            moveProduct(direction) {
                this.$emit('move', {direction: direction, index: this.index});
            },
            removeProduct() {
                this.products.splice(this.index, 1);
            }
        }
    }
</script>

<style scoped>

</style>