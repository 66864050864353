<template>
    <vs-popup id="product-change--modal" :title="title" :active="visible" @close="onClickClose" class="product-modal">
        <div id="product-change--loader" class="vs-con-loading__container overflow-visible">
            <template v-if="item">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-3/3 w-full" v-if="item.article">
                        <div><u>Aktuell:</u>  Nr.: {{item.article.ordernumber}} - {{item.article.name}}</div>
                        <div v-if="selectedProduct"><u>Neu:</u>  Nr.: {{selectedProduct.ordernumber}} - {{selectedProduct.name}}</div>


                        <mg-select :options="products"
                                   track-by="id"
                                   label="name"
                                   placeholder="Produkt auswählen"
                                   v-model="productId"
                                   @select="onSelectProduct"
                                   @change="onSelectProduct"
                                   @remove="selectedProduct=null"
                                   class="mt-4">

                        </mg-select>


                        <div class="new-description-preview-container" v-if="selectedProduct">

                            <fieldset class="mt-3 " id="wrapper-name">
                                <legend class="underline">Welchen Title möchtest du verwenden:</legend>

                                <div>
                                    <div v-if="selectedProduct.name && selectedProduct.name.length > 0">
                                        <input type="radio" class="custom-control-input" v-model="selectedTitleOption" @click="setSelectedTitle(selectedProduct.name)"  value="name" id="name">
                                        <label for="name"> Name
                                                <svg xmlns="http://www.w3.org/2000/svg" style="width: 20px" viewBox="0 0 5 3"><path d="M0 0h5v3H0z"/><path fill="#D00" d="M0 1h5v2H0z"/><path fill="#FFCE00" d="M0 2h5v1H0z"/></svg>
                                        </label>
                                    </div>
                                    <div v-if="selectedProduct.name_en && selectedProduct.name_en.length > 0">
                                        <input type="radio" class="custom-control-input" v-model="selectedTitleOption"  @click="setSelectedTitle(selectedProduct.name_en)"  value="name_en" id="name_en">
                                        <label for="name_en"> name
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30"
                                                 style="width: 20px">
                                                <clipPath id="a">
                                                    <path d="M0 0v30h60V0z"/>
                                                </clipPath>
                                                <clipPath id="b">
                                                    <path d="M30 15h30v15zv15H0zH0V0zV0h30z"/>
                                                </clipPath>
                                                <g clip-path="url(#a)">
                                                    <path d="M0 0v30h60V0z" fill="#012169"/>
                                                    <path d="M0 0l60 30m0-30L0 30" stroke="#fff" stroke-width="6"/>
                                                    <path d="M0 0l60 30m0-30L0 30" clip-path="url(#b)" stroke="#C8102E"
                                                          stroke-width="4"/>
                                                    <path d="M30 0v30M0 15h60" stroke="#fff" stroke-width="10"/>
                                                    <path d="M30 0v30M0 15h60" stroke="#C8102E" stroke-width="6"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </div>

                            </fieldset>

                            <fieldset class="mt-3 " id="wrapper-description">
                                <legend class="underline">Welche Beschreibung möchtest du verwenden:</legend>

                                <div>
                                    <div v-if="selectedProduct.description && selectedProduct.description.length > 0">
                                        <input type="radio" class="custom-control-input" v-model="selectedContentOption"  @click="setSelectedContent(selectedProduct.description)"  value="description" id="description">
                                        <label for="description"> Beschreibung (RE+GU) -
                                                <svg xmlns="http://www.w3.org/2000/svg" style="width: 20px" viewBox="0 0 5 3"><path d="M0 0h5v3H0z"/><path fill="#D00" d="M0 1h5v2H0z"/><path fill="#FFCE00" d="M0 2h5v1H0z"/></svg>
                                        </label>
                                    </div>
                                    <div v-if="selectedProduct.description_en && selectedProduct.description_en.length > 0">
                                        <input type="radio" class="custom-control-input" v-model="selectedContentOption"  @click="setSelectedContent(selectedProduct.description_en)"  value="description_en" id="description_en">
                                        <label for="description_en"> Beschreibung (RE+GU) -
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30"
                                                 style="width: 20px">
                                                <clipPath id="a">
                                                    <path d="M0 0v30h60V0z"/>
                                                </clipPath>
                                                <clipPath id="b">
                                                    <path d="M30 15h30v15zv15H0zH0V0zV0h30z"/>
                                                </clipPath>
                                                <g clip-path="url(#a)">
                                                    <path d="M0 0v30h60V0z" fill="#012169"/>
                                                    <path d="M0 0l60 30m0-30L0 30" stroke="#fff" stroke-width="6"/>
                                                    <path d="M0 0l60 30m0-30L0 30" clip-path="url(#b)" stroke="#C8102E"
                                                          stroke-width="4"/>
                                                    <path d="M30 0v30M0 15h60" stroke="#fff" stroke-width="10"/>
                                                    <path d="M30 0v30M0 15h60" stroke="#C8102E" stroke-width="6"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                    <div v-if="selectedProduct.offer_description && selectedProduct.offer_description.length > 0">
                                        <input type="radio" class="custom-control-input" v-model="selectedContentOption" @click="setSelectedContent(selectedProduct.offer_description)"  value="offer_description" id="offer_description">
                                        <label for="offer_description"> Beschreibung (Angebot|AB) -
                                            <svg xmlns="http://www.w3.org/2000/svg" style="width: 20px" viewBox="0 0 5 3"><path d="M0 0h5v3H0z"/><path fill="#D00" d="M0 1h5v2H0z"/><path fill="#FFCE00" d="M0 2h5v1H0z"/></svg>
                                        </label>
                                    </div>
                                    <div v-if="selectedProduct.offer_description_en && selectedProduct.offer_description_en.length > 0">
                                        <input type="radio" class="custom-control-input" v-model="selectedContentOption"  @click="setSelectedContent(selectedProduct.offer_description_en)"  value="offer_description_en"  id="offer_description_en">
                                        <label for="offer_description_en"> Beschreibung (Angebot|AB) -
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30"
                                                 style="width: 20px">
                                                <clipPath id="a">
                                                    <path d="M0 0v30h60V0z"/>
                                                </clipPath>
                                                <clipPath id="b">
                                                    <path d="M30 15h30v15zv15H0zH0V0zV0h30z"/>
                                                </clipPath>
                                                <g clip-path="url(#a)">
                                                    <path d="M0 0v30h60V0z" fill="#012169"/>
                                                    <path d="M0 0l60 30m0-30L0 30" stroke="#fff" stroke-width="6"/>
                                                    <path d="M0 0l60 30m0-30L0 30" clip-path="url(#b)" stroke="#C8102E"
                                                          stroke-width="4"/>
                                                    <path d="M30 0v30M0 15h60" stroke="#fff" stroke-width="10"/>
                                                    <path d="M30 0v30M0 15h60" stroke="#C8102E" stroke-width="6"/>
                                                </g>
                                            </svg>
                                        </label>
                                    </div>
                                </div>

                            </fieldset>
                            <div class="preview-content" >
                                <div v-html="selectedTitle" class="text-bold bold"></div>
                                <div v-html="selectedContent"></div>
                            </div>
                        </div>

                    </div>
                    <div class="flex justify-end mt-4 ml-4">
                        <vs-button color="danger" type="border" @click="onClickClose">Abbrechen</vs-button>
                        <vs-button color="success" class="ml-4" @click="onUpdateProduct" :disabled="!selectedProduct || !selectedTitleOption ">Speichern
                        </vs-button>
                    </div>
                </div>
            </template>
        </div>
    </vs-popup>
</template>

<script>
    import ApiService from "../../api";
    import BooleanIcon from "../boolean-icon/BooleanIcon";
    import MgSelect from "../mg-select/MgSelect";
    import qs from "qs";
    import QueryHelper from "@/mixins/helper/query.helper";
    import moment from "moment/moment";


    export default {
        name: "ChangeArticleModal",
        components: {BooleanIcon, MgSelect},
        props: {
            item: {
                default: null
            },
            active: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                title: 'Artikel tauschen',
                product: null,
                selectedProduct: null,
                products: [],
                productId: null,
                selectedTitleOption: null,
                selectedTitle: null,
                selectedContentOption: null,
                selectedContent: null,
                queryParams: {
                    sort: [],
                    filters: [],
                    global_search: "",
                    per_page: 9999,
                    page: 1,
                },
            }
        },

        computed: {
            visible() {
                return this.active;
            },
            requestParams() {
                let queryParams = QueryHelper.parseRequestParams(this.queryParams);

                queryParams.filter.push({
                    field: 'active',
                    value: '1',
                    expression: 'exact'
                });

                queryParams.order =
                    {
                        'field': 'name',
                        'direction': 'desc'
                    }
                ;

                return queryParams;
            }
        },

        methods: {
            fetchOrderItem() {
                //this.openLoading();

                ApiService.get(`order_items/${this.item.item_id}`).then(response => {

                    if (response.status !== 200) {
                        //this.closeLoading();
                        return;
                    }

                    this.product = response.data.result;

                });
            },

            onClickClose() {
                this.selectedProduct = null;
                this.setSelectedTitle(null);
                this.setSelectedContent(null);
                this.$emit('update:active', false)
            },

            onActiveChange(value) {
                if (value) {
                    if (this.item !== null && this.productId !== '') {
                        this.fetchOrderItem();
                        this.fetchProducts();
                    }
                }
            },

            setSelectedTitle(string){
                this.selectedTitle = string;
            },
            setSelectedContent(string){
                this.selectedContent = string;
            },

            openLoading() {
                this.$vs.loading({
                    container: '#product-change--loader',
                    scale: 0.6
                });
            },

            closeLoading() {
                this.$vs.loading.close('#product-change--loader > .con-vs-loading');
            },
            fetchProducts() {
                this.openLoading();
                ApiService.get('articles', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {

                    if (response.status !== 200) {
                        return;
                    }

                    this.products = response.data.result;

                    if(this.productId !== null && this.product === null) {
                        this.onSelectProduct(this.products.find(item => item.id = this.productId));
                    }

                    this.closeLoading();

                });
            },
            onUpdateProduct() {

                if (this.selectedProduct == undefined || this.selectedProduct == null || this.selectedTitleOption == null) {
                    this.$vs.notify({
                        title: 'Fehler',
                        text: 'Kein Produkt ausgewählt',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'error'
                    })
                    return;
                }

                var payload = {
                    article_assignment_id: this.selectedProduct.id
                };

                this.item.id = this.selectedProduct.id
                this.item.ordernumber = this.selectedProduct.ordernumber
                this.item.article = this.selectedProduct
                this.item.name = this.selectedTitle

                if (this.selectedContentOption){
                    this.item.description = this.selectedContent
                }


                this.onClickClose();
            },
            onSelectProduct(product) {

                product.amount = parseInt(product.min_amount);
                product.volume_unit = parseInt(product.unit);
                product.is_grouped = this.addToGroup;
                product.optional = false;
                product.discount = 0;

                this.selectedProduct = JSON.parse(JSON.stringify(product));
            },
        },

        watch: {
            active: 'onActiveChange'
        }
    }
</script>

<style scoped>
.preview-content {
    border: 1px solid #bfc3cb;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 0.375rem;
    background-color: #dadee0;
}
.bold {
    font-weight: bold;
}
</style>