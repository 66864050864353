<template>
    <div class="invoice-table--row row-heading" :class="{'border-none': index === (products.length - 1)}" v-on:dblclick="onInlineEdit" >
        <div class="invoice-table--col col-center w-1/16">
            <div class="col-position">
                <div class="invoice-table--row-position">
                    <feather-icon icon="ChevronUpIcon" svgClasses="position-up"
                                  @click="moveProduct(-1)"/>
                    <feather-icon icon="ChevronDownIcon" svgClasses="position-down"
                                  @click="moveProduct(1)"/>
                </div>
                <template v-if="!showInlineEdit">
                    {{index+1}}
                </template>
                <template v-else >
                    <div>
                        <vs-row vs-w="12">
                            <vs-input size="small" v-model="indexClone" class="w-full mb-2"></vs-input>
                        </vs-row>
                        <vs-row vs-w="12">
                            <div class="invoice-table--row-save" @click="saveIndex()">
                                <feather-icon icon="SaveIcon" class="w-4 h-4"/>
                            </div>
                        </vs-row>
                    </div>
                </template>
            </div>
        </div>

        <div class="invoice-table--col w-1/8 pt-5">
            <b>Überschrift: </b>
        </div>

        <div class="flex-1 invoice-table--col">
            <vs-input v-model="product.name" class="w-full"></vs-input>
        </div>

        <div class="invoice-table--row-delete" @click="removeProduct()">
            <feather-icon icon="DeleteIcon" class="w-6 h-6"/>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            index: {
                type: Number
            },
            products: {
                type: Array
            },
            product: {
                type: Object
            }
        },
        name: "RowHeading",
        data() {
            return {
                indexClone: null,
                showInlineEdit: false,
            }
        },
        methods: {
            moveProduct(direction) {
                this.$emit('move', {direction: direction, index: this.index});
            },
            removeProduct() {
                this.products.splice(this.index, 1);
            },
            onInlineEdit() {
                this.indexClone = JSON.parse(JSON.stringify(this.index)) + 1;
                this.showInlineEdit = true
            },
            saveIndex() {
                this.indexClone = this.indexClone - 1

                if(this.indexClone < this.index) {
                    let direction = this.index - this.indexClone
                    this.$emit('move', {direction: - direction, index: this.index});
                } else if(this.indexClone > this.index) {
                    let direction = this.indexClone - this.index
                    this.$emit('move', {direction: direction, index: this.index});
                }
                this.indexClone = this.indexClone + 1
                this.showInlineEdit = false
            }
        }
    }
</script>

<style scoped>

</style>