<template>
    <transition name="popup-t">
        <div class="vs-component con-vs-popup client-select" v-show="isActive" @click="onClose">
            <div class="vs-popup--background client-select--background">
                <div class="client-select--popup search-select">

                    <vs-input ref="input" class="w-full" icon="search" icon-after="true" size="large" placeholder="Nach einem Kunden suchen..." v-model="searchTerm" @input="fetchResults"></vs-input>
                    <input type="hidden" v-model="value">

                    <div class="search-select--results" v-if="searchTerm">
                        <div class="results-item no-results" v-show="results.length < 1">
                            Es wurden keine Ergebnisse gefunden.
                        </div>
                        <div class="results-item" v-for="result in results" @click="selectOption(result)">
                            {{result.company_name}}
                        </div>
                        <div class="search-select--pagination">
                            <vs-button icon="first_page" color="dark" size="small" :disabled="currentPage <= 1" @click="changePage(1)"></vs-button>
                            <vs-button icon="navigate_before" color="dark" size="small" :disabled="currentPage <= 1" @click="changePage(currentPage-1)"></vs-button>
                            <vs-input size="small" v-model="currentPage" @input="changePage"></vs-input>
                            <div class="total-pages">
                                {{ totalPages }}
                            </div>
                            <vs-button icon="navigate_next" color="dark" size="small" :disabled="currentPage >= totalPages" @click="changePage(currentPage+1)"></vs-button>
                            <vs-button icon="last_page" color="dark" size="small" :disabled="currentPage >= totalPages" @click="changePage(totalPages)"></vs-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import ApiService from "../../api";

    export default {
        name: "ClientSelectorPopup",
        props: ['value', 'isActive'],
        data() {
            return {
                searchTerm: null,
                currentPage: 1,
                totalPages: 0,
                limit: 5,
                results: [],
                resultKey: 'company_name',
            }
        },
        computed: {
            queryParams() {
                let offset = 0;

                if(this.currentPage > 1) {
                    offset = this.limit * (this.currentPage - 1);
                }

                let query = '?search=' + this.searchTerm;
                query += '&limit=' + this.limit;
                query += '&offset=' + offset;

                return query;
            }
        },
        methods: {
            onClose(event) {
                if(event.target.className && event.target.className.indexOf && event.target.className.indexOf('client-select') !== -1) {
                    this.results = [];
                    this.searchTerm = null;
                    this.$emit('close');
                }
            },
            fetchResults() {
                ApiService.get('clients' + this.queryParams).then(response => {
                    this.results = response.data.result;
                    this.totalPages = Math.ceil(response.data.total / this.limit);

                    if(this.totalPages < this.currentPage) {
                        this.currentPage = this.totalPages + 1;
                    }
                })
            },
            changePage(page) {
                page = parseInt(page);

                if(page < 1 || page > this.totalPages) {
                    return;
                }

                this.currentPage = page;

                this.fetchResults();
            },
            selectOption(item) {
                this.results = [];
                this.searchTerm = null;

                ApiService.get('clients/' + item.id).then(response => {
                    const client = response.data.result;
                    this.$emit('input', client);
                    this.$emit('close');
                });
            }
        },
        watch: {
            isActive(newValue, oldValue) {
                if(newValue === true) {
                    this.$nextTick(() => {
                        this.$refs.input.focusInput();
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
</style>