<template>
    <div class="offer-edit">
        <invoice-page order-type="offer" :invoice-edit="true" enableOptionalItems></invoice-page>
    </div>
</template>

<script>
    import InvoicePage from "../../../components/invoice/InvoicePage";

    export default {
        name: "Offer",
        components: {InvoicePage},
    }
</script>

<style scoped>

</style>