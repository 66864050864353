<template>
  <div class=" w-3/4">
    <div class="invoice-layout rounded p-12 bg-white shadow-lg w-full">
      <vs-alert color="primary" :active="true" icon="error" class="mb-4" v-show="!client">
        Bitte wählen Sie einen Kunden aus.
      </vs-alert>

      <template v-if="client">
        <div class="flex w-full mb-16">
          <div class="w-full">
            <img :src="workspace.detail.logo_url" alt="Mediagraphik" class="w-1/4">
          </div>
        </div>

        <div class="flex w-full mb-16" style="min-height: 200px">
          <div class="w-1/2 h-4">
            <vs-alert color="#de5c00" :active="client.country != 1" icon="info" class="mb-2 mt-2 " >
              Dokument auf English verfassen!
            </vs-alert>

            <p class="text-xs mb-2">{{ workspace.detail.company_name }} - {{ workspace.detail.street }} - {{ workspace.detail.zipcode }} {{ workspace.detail.city }}</p>
            <div class="invoice-layout--address">
              <p>{{client.company_name}}</p>
              <p>{{client.address_line_1}}</p>
              <p v-if="client.address_line_2">{{client.address_line_2}}</p>
              <p>{{client.zip_code}} {{client.city}}</p>
              <p v-if="client.country != 1">
                <b>{{getCountryNameById(client.country)}}</b><br>
                <i>Ust. Id: {{client.ust_id}}</i>
              </p>
              <span class="link d-block" v-if="client.country != 1" @click="fetchEnglishOrderType">Englische Angebotsvorlage verweden </span>

              <vs-alert color="#de5c00" :active="client.country != 1 && (!client.ust_id || client.ust_id.length < 5)" icon="info" class="mb-2 mt-2 " >
                  Der Rechnungsempfänger kommt nicht aus Deutschland! <br>KEINE UstId hinterlegt!!!!!
              </vs-alert>

            </div>
          </div>
          <div class="w-1/2 text-right">
            <p>{{ workspace.detail.company_name }}</p>
            <p>{{ workspace.detail.street }}</p>
            <p>{{ workspace.detail.zipcode }} {{ workspace.detail.city }}</p>
            <br/>
            <p>Tel: {{ workspace.detail.phone }}</p>
            <p>Mail: {{ workspace.detail.invoice_email }}</p>
            <div class="mt-4" v-if="(!isDraft || isOrderConfirmation || isOfferBilling) && order.invoice_number && orderType.invoice_type !== 'invoice'">
              <p><b>
                <slot name="invoice-number">{{orderType.name}} Nr. :</slot>
              </b> {{data.invoice_number}}
              </p>
            </div>
            <div class="mt-2" v-if="(isCancellation || isOrderConfirmation || isOfferBilling) && order.invoice_number">
              <p><b>
                <slot name="referrer-number">zu {{order.type.name}}-Nummer :</slot>
              </b> {{order.invoice_number}}
              </p>
            </div>
          </div>
        </div>

        <div class="flex w-full pt-4 mt-4 items-center">
          <div class="flex-1 pt-2">
            <vs-input v-model="data.title" class="w-full" placeholder="Untertitel (intern)"></vs-input>
          </div>
          <div class="w-1/8 text-right pl-5" v-if="!isDraft && !isCancellation">
            <p class="text-sm text-left mb-1">
              <slot name="invoice-date">Datum :</slot>
            </p>
            <datepicker v-model="data.order_date" placeholder="Datum wählen" class="mb-4"
                        format="dd.MM.yyyy"></datepicker>
          </div>
        </div>

        <div class="order-type" v-if="orderType.invoice_type == 'offer'">
          <div class="flex">
            <div>
              <vs-input v-model="order.alternativ_type_name" v-if="editOrderTypeText"></vs-input>
            </div>
            <div>
              <feather-icon icon="SendIcon" class="inline-block ml-2  pt-3 btn" svgClasses="w-4 h-4"
                            @click="editOrderTypeText = false" v-if="editOrderTypeText"/>
            </div>
          </div>

          <div v-if="!editOrderTypeText" >
            <h2>
              <span v-if="order.alternativ_type_name">{{order.alternativ_type_name}}</span>
              <span v-else>{{orderType.name}}</span>
              Nr.
              <span v-if="order.invoice_number">{{order.invoice_number}}</span>
              <span v-else> XXXXXXXX</span>
              <feather-icon icon="Edit2Icon"  class="inline-block ml-2 btn" svgClasses="w-4 h-4" @click="editOrderTypeText = true"  v-if="!editOrderTypeText"   />
            </h2>
          </div>

        </div>
        <div class="flex w-full mt-2">

          <feather-icon icon="SendIcon"  class="inline-block mr-2 btn" svgClasses="w-4 h-4" @click="editHeaderText = false" v-if="editHeaderText"  />
          <editor api-key="qxbzwh7n69tctq7p5bta4qtatjs7c35wi7juwgf2rcpf29lu" :init="initTinyMce"
                  v-model="data.header_text" class="w-full" v-if="editHeaderText"/>

          <div v-if="!editHeaderText" >
            <feather-icon icon="Edit2Icon"  class="inline-block mr-2 btn" style="float: left" svgClasses="w-4 h-4" @click="editHeaderText = true"   />
            <div  v-html="data.header_text"  style="float: left">
              {{data.header_text}}
            </div>
          </div>


        </div>
        <div class="relative" :class="{'hide-remove':isCancellation}">
          <div class="invoice-overlay" v-show="isCancellation"></div>
          <div class="flex mt-4 invoice-table--heading">
            <div class="w-1/16 p-3">Pos</div>
            <div class="flex-1 p-3">Bezeichnung</div>
            <div class="w-1/7 p-3">Anzahl</div>
            <div class="w-1/8 p-3">Einzelpreis</div>
            <div class="w-1/8 p-3">Gesamt</div>
            <div class="w-1/12 p-3 text-center" v-if="enableOptionalItems">Optional</div>
          </div>
          <div class="invoice-items">
            <template v-for="(product, index) in data.products"
                      :class="{'border-none':index === data.products.length - 1}">
              <row-product v-if="product.type === 'article' && product.is_grouped !== true"
                           :index="index"
                           :product="product"
                           :products="data.products"
                           :contract-conditions="contractConditions"
                           :order="order"
                           @edit-row="editProduct"
                           @product-details-open="openProductDetail"
                           @product-change-open="openProductChangeModal"
                           :is-grouped="product.is_grouped"
                           @move="moveProduct"
                           :enable-optional="enableOptionalItems">

              </row-product>

              <row-heading v-else-if="product.type === 'heading'" :index="index" :product="product"
                           @move="moveProduct"
                           :products="data.products"></row-heading>

              <row-break v-else-if="product.type === 'break'" :index="index" :product="product"
                         @move="moveProduct"
                         :products="data.products"></row-break>

              <row-sub-total v-else-if="product.type === 'sub_total'" :index="index" :product="product"
                             @move="moveProduct"
                             :products="data.products"></row-sub-total>

              <row-group v-else-if="product.type === 'group'"
                         :index="index"
                         :product="product"
                         :products="data.products"
                         @move="moveProduct"
                         @add-product="addGroupProduct"
                         @show-products="showGroupProducts"
                         @edit-group="editGroup"
                         @product-details-open="openProductDetail"
                         @product-change-open="openProductChangeModal"
                         @edit-product="editGroupProduct"
                         :enable-optional="enableOptionalItems"
              ></row-group>
            </template>
          </div>

          <div class="invoice-table--add-row mt-6" v-show="!isCancellation">
            <vs-dropdown class="vs-button vs-button-success vs-button-filled">
              <div class="add-body">
                <feather-icon icon="PlusSquareIcon" class="mr-2"/>
                Position hinzufügen
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item @click="addProduct">
                  Artikel Position
                </vs-dropdown-item>
                <vs-dropdown-item @click="addSubTotal">
                  Zwischensumme
                </vs-dropdown-item>
                <vs-dropdown-item @click="addBreak">
                  Seitenumbruch
                </vs-dropdown-item>
                <vs-dropdown-item @click="addHeading">
                  Überschrift
                </vs-dropdown-item>
                <vs-dropdown-item @click="addGroup">
                  Artikel Gruppe
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div class="invoice-summary flex flex-col items-end mt-3 pt-3">
              <div class="w-1/3 flex mt-4">
                  <vs-alert color="primary" :active="data.discount > 0" icon="error" class="mb-4">
                      Ein Rabatt auf das komplette Angebot ist <b>- nicht -</b> zu empfehlen. Dies kann nicht in eine Rechnung überführt werden.
                  </vs-alert>
              </div>
            <div class="w-1/3 flex text-right mb-2 flex items-center">
              <div class="w-full font-bold flex justify-end">
                <vs-input v-model="data.discount" placeholder="Rabatt in %"/>
              </div>
            </div>
            <div class="w-1/3 flex text-right mb-2 flex items-center">
              <div class="w-2/3 ">Rabatt:</div>
              <div class="w-1/3 ">{{discountSum()}} €</div>
            </div>
            <div class="w-1/3 flex text-right mb-2">
              <div class="w-2/3 ">MwSt:</div>
              <div class="w-1/3 ">{{sumDifference()}} €</div>
            </div>
            <div class="w-1/3 flex text-right mb-2">
              <div class="w-2/3 ">Summe (Brutto):</div>
              <div class="w-1/3 ">{{sumTotalTax()}} €</div>
            </div>
            <div class="w-1/3 flex text-right mb-2" v-if="orderType.invoice_type === 'offer'">
              <div class="w-2/3 ">Total Manntag/e:</div>
              <div class="w-1/3 ">{{sumTotalManDays(true)}}<span v-if="sumTotalManDays(true) != sumTotalManDays(true,true)"> - {{sumTotalManDays(true,true)}}</span></div>
            </div>
            <div class="w-1/3 flex text-right mb-2" v-if="orderType.invoice_type === 'offer'">
              <div class="w-2/3 ">Total Manntag/e (incl. Optional):</div>
              <div class="w-1/3 ">{{sumTotalManDays()}} <span v-if="sumTotalManDays() != sumTotalManDays(false,true)"> - {{sumTotalManDays(false,true)}}</span></div>
            </div>
            <div class="w-1/3 flex text-right text-lg border-0 border-solid border-t border-gray-400 pt-2">
              <div class="w-2/3 font-bold">Summe (Netto):</div>
              <div class="w-1/3 font-bold">{{sumTotal()}} €</div>
            </div>
            <div class="w-1/3 flex text-right text-lg border-0 pt-2" v-if="enableOptionalItems">
              <div class="w-2/3 font-bold">Summe mit optional (Netto):</div>
              <div class="w-1/3 font-bold">{{sumTotal(true)}} €</div>
            </div>
            <div class="w-1/3 flex text-right text-lg border-0 pt-2" v-if="enableOptionalItems">
              <div class="w-2/3 ">Summe <br><small>mit Maximalen Aufwänden ohne optionalen Positionen (Netto):</small></div>
              <div class="w-1/3 ">{{sumTotal(false,false,true)}} €</div>
            </div>
            <div class="w-1/3 flex text-right text-lg border-0 mt-5 pt-2"  v-if="order && edit && orderType.invoice_type === 'offer'">
              <div class="w-2/3 font-bold">Budgetgrenze:</div>
              <div class="w-1/3 font-bold"> <vs-input v-model="order.sum_max_budget" class="w-full small-input" placeholder="Maximales Budget"></vs-input> €</div>
            </div>

            <div class="w-1/3 flex text-right text-lg border-0 "  v-if="order && edit && orderType.invoice_type === 'offer' && isMaxBudgetSmallerThenMaxAmounts">
              <vs-alert color="#de5c00" :active="true" icon="info" class="mb-2 mt-5 " >
                Vorsicht: Das maximal hinterlegte Budget ohne Optionale Positionen übersteigt bereits die Budgetgrenze.
              </vs-alert>
            </div>
            <div class="w-1/3 flex text-right text-lg border-0 " >
              <vs-alert color="#de5c00" :active="client.country != 1" icon="info" class="mb-2 mt-2 " >
                Der Rechnungsempfänger kommt nicht aus Deutschland! <br>Es darf keine MwSt berechnet werden.
              </vs-alert>
            </div>
          </div>
        </div>

        <div class="flex w-full mt-6">
          <feather-icon icon="SendIcon"  class="inline-block mr-2 btn" svgClasses="w-4 h-4" @click="editFooterText = false" v-if="editFooterText"  />
          <editor api-key="qxbzwh7n69tctq7p5bta4qtatjs7c35wi7juwgf2rcpf29lu" :init="initTinyMce"
                  v-model="data.footer_text" class="w-full" v-if="editFooterText"/>

          <div v-if="!editFooterText" >
            <feather-icon icon="Edit2Icon"  class="inline-block mr-2 btn" svgClasses="w-4 h-4" @click="editFooterText = true"   />
            <div  v-html="data.footer_text" >
              {{data.footer_text}}
            </div>
          </div>



        </div>
          <div class="vs-row mt-3"  v-if="orderType.invoice_type === 'offer'" >
              <i v-if="order.offer_valid_to != null" class="offer-valid-to">Das Angebot ist begrenzt bis zum {{ formatDate(order.offer_valid_to)}}</i>
              <i v-else>Das Angebot hat keine zeitlich begrenzte Gültigkeit.</i>
          </div>

          <div class="w-2/3 flex text-lg mt-8 pt-8"  style="border-top:2px solid grey"  v-if="order && edit && orderType.invoice_type === 'invoice' && userHasPermission('view_bank_transactions')" >
              <div class="w-2/3 ">
                  <span class="font-bold">Interner Verrechnungssatz für den bereinigten Umsatz:<br></span>
                  <small>Dieser Wert wird dann für die Berechnung der Statistiken verwendet. Wenn Rechnungen zwischen einer Unternehmensgruppe geschrieben werden, sollten diese hier entwertet werden.</small>
              </div>
              <div class="w-1/3 font-bold">
                  <vs-input v-model="order.adjusted_invoice_sum_netto" class="w-full mt-2" placeholder="Bereinigte Summe Netto €"></vs-input>
              </div>
          </div>

        <product-modal :active.sync="productModalActive" :product="selectedProduct"
                       :product-index="selectedProductIndex" @updated="updateProduct"
                       :add-to-group="addProductToGroup" :group-index="visibleGroupIndex"
                       @close="productModalActive=false"></product-modal>

      </template>
    </div>

    <vs-row vs-w="12">

      <vs-col vs-offset="6" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" v-if="data.title && client">
        <vs-alert color="#de5c00" :active="data.title.length < 20" icon="info" class="mb-2 mt-5 " v-show="data.title.length < 20" >
          Möchtest du nicht eine aussagekräftigeren <b>Belegtitle</b> vergeben?<br> <small>Dein Title scheint sehr kurz zu sein!</small>
        </vs-alert>
      </vs-col>
      <vs-col vs-offset="6" vs-type="flex" vs-justify="center" vs-align="center" vs-w="6" v-if="order && edit && !currentOrderStatus.is_draft && orderType.invoice_type === 'offer'">
        <vs-alert color="#de5c00" :active="isOrderDayExactToday" icon="info" class="mb-2 mt-5 " v-show="isOrderDayExactToday" >
          Das Angebotsdatum entspricht nicht dem heutigen Tag, ggf. auf heute ändern.
        </vs-alert>
      </vs-col>
    </vs-row>

    <div class="invoice-layout--actions" v-show="client" v-if="edit">
      <vs-button color="primary" type="border" :to="{name: $route.meta.parent}">Abbrechen</vs-button>
      <vs-button color="dark" v-if="currentOrderStatus.is_draft" target :href="publicUrl + 'document/' + workspace.id + '/order/' + order.id">
        Vorschau ansehen
      </vs-button>
      <vs-button color="success" @click="saveInvoice" v-if="!currentOrderStatus.is_draft"  :disabled="errors.items.length >=1">
        <slot name="invoice-save">{{orderType.name}} speichern</slot>
      </vs-button>
      <vs-button color="success" @click="saveInvoiceWithoutPDFGenerate" v-if="(!currentOrderStatus.is_draft  || isOrderConfirmation || isOfferBilling) && orderType.invoice_type !== 'invoice'"  :disabled="errors.items.length >=1">
        <slot name="invoice-save">Speichern {{orderType.name}} ohne PDF-Änderung</slot>
      </vs-button>
      <vs-button color="success" @click="saveWithStatus" v-else  :disabled="errors.items.length >=1">
        <slot name="invoice-save">Entwurf speichern</slot>
      </vs-button>
      <vs-button color="success" @click="saveOpen" v-if="currentOrderStatus.is_draft"  :disabled="errors.items.length >=1">
        <slot name="invoice-save">
          {{orderType.name}} erstellen
        </slot>
      </vs-button>
    </div>

    <div class="invoice-layout--actions" v-if="!client">
      <vs-button color="primary" type="border" :to="{name: $route.meta.parent}">Abbrechen</vs-button>
    </div>

    <div class="invoice-layout--actions" v-if="!edit && client">
      <vs-button color="primary" type="border" :to="{name: $route.meta.parent}">Abbrechen</vs-button>
      <vs-button color="primary" type="border" @click="saveDraft" :disabled="errors.items.length >=1">Entwurf speichern</vs-button>
      <vs-button color="success" @click="saveOpen" :disabled="errors.items.length >=1">
        <slot name="invoice-save">
          {{orderType.name}} erstellen
        </slot>
      </vs-button>
    </div>
    <product-detail-modal :product-id="selectedProductId" :active.sync="productDetailModalOpen"
                          @close="productDetailModalOpen=false"></product-detail-modal>

    <change-article-modal :item="selectedChangedProductItem" :active.sync="productChangeModalOpen"
                          @close="productChangeModalOpen=false"></change-article-modal>




  </div>
</template>

<script>
import staticOptions from "../../mixins/static/options";
import ProductModal from "./ProductModal";
import PriceHelper from "../../mixins/helper/price.helper";
import moment from 'moment';
import {mapGetters} from "vuex";
import ApiService from "../../api";
import RowProduct from "./RowProduct";
import RowHeading from "./RowHeading";
import RowBreak from "./RowBreak";
import RowSubTotal from "./RowSubTotal";
import Datepicker from '@sum.cumo/vue-datepicker';
import OrderHelper from "../../mixins/helper/order.helper";
import ProductDetailModal from "../product/ProductDetailModal";
import ChangeArticleModal from "../product/ChangeArticleModal";
import RowGroup from "./RowGroup";
import ArticleCustomPricesService from "../../mixins/services/article-custom-prices.service";
import SendInvoiceModal from "./SendInvoiceModal";
import InvoicePriceCalculator from "./invoice-price-calculator";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "Invoice",
  components: {
    SendInvoiceModal,
    RowGroup,
    ProductDetailModal, RowSubTotal, RowBreak, RowHeading, RowProduct, ProductModal, Datepicker, Editor, ChangeArticleModal
  },
  props: ['client', 'status', 'order', 'edit', 'typeId', 'orderType', 'enableOptionalItems', 'statusOptions', 'isCancellation', 'isOfferBilling', 'isOrderConfirmation','selectedEmployee','selectedOfferBillingType','selectedSeparateInvoice'],
  data() {
    return {
      data: {},
      selectedProductIndex: null,
      selectedProductId: null,
      productModalActive: false,
      productDetailModalOpen: false,
      productChangeModalOpen: false,
      selectedChangedProductItem: [],
      visibleGroupIndex: null,
      addProductToGroup: false,
      editHeaderText: false,
      editOrderTypeText: false,
      editFooterText: false,
      initTinyMce: {
        height: "200",
        width: "100%",
        menubar: false,
      },
      orderStatus: null,
      customPriceService: new ArticleCustomPricesService(),
      sendInvoiceModalActive: false,
      sendMailData: null,
      compiledMailTemplate: null,
      contractConditions: [],
    }
  },
  created() {
    this.fetchContractConditions();
    this.resetData();
  },

  computed: {
    ...mapGetters(['userId','workspace', 'userHasPermission']),
    publicUrl() {
      return process.env.VUE_APP_PUBLIC_URL;
    },
    taxOptions() {
      return staticOptions.tax;
    },
    unitOptions() {
      return staticOptions.units;
    },
    selectedProduct() {
      if (this.selectedProductIndex === null) {
        return null;
      }

      if (this.addProductToGroup) {
        return this.data.products[this.visibleGroupIndex].group_products[this.selectedProductIndex];
      }

      return this.data.products[this.selectedProductIndex];
    },
    currentReadableDate() {
      return moment().format('DD.MM.YYYY')
    },
    currentOrderStatus() {
      if (this.status != undefined && this.status != null) {
        return this.statusOptions.find(item => item.id === parseInt(this.order.status));
      }


      return null;
    },
    sendInvoiceEmails() {
      let emails = [];

      if (!this.client) {
        return null;
      }

      if (this.invoiceRecipientContact && this.invoiceRecipientContact.length > 1) {
        emails = this.invoiceRecipientContact.map(item => item.email);
        if (!this.additionalInvoiceRecipient) {
          return emails.join(';');
        }

        return emails.concat(this.additionalInvoiceRecipient).join(';');
      }

      if (!this.additionalInvoiceRecipient) {
        return this.client.email;
      }

      return this.additionalInvoiceRecipient.join(';');
    },
    invoiceRecipientContact() {
      if (!this.client) {
        return null;
      }

      if (this.client.contacts === null) {
        return null;
      }

      const contact = this.client.contacts.filter(item => item.pivot.is_invoice_recipient);

      if (contact) {
        return contact
      }

      return null;
    },
    additionalInvoiceRecipient() {
      if (!this.client) {
        return null;
      }

      if (this.client.additional_invoice_recipient) {
        this.client.additional_invoice_recipient.split(';');
      }

      return null;
    },
    isDraft() {
      if (this.status == undefined && this.status == null) {
        return false;
      }

      const status = this.statusOptions.find(item => item.id === parseInt(this.status));

      if (status == undefined && status == null) {
        return false;
      }
      return status.is_draft;
    },
    isOrderDayExactToday() {
      if(moment(this.data.order_date).format('YYYY-MM-DD') != moment().format('YYYY-MM-DD')) {
        return true
      }
      return false;
    },
    isMaxBudgetSmallerThenMaxAmounts() {
      if (this.order.sum_max_budget && this.order.sum_max_budget <=  PriceHelper.parseCommaFloat(this.sumTotal(false,false,true)) ) {
        return true;
      }else {
        return false
      }
    }
  },
  methods: {
    sum(optional = false, withTax = false, withMaxAmount = false) {

      if (this.data.products.length === 0) {
        return 0;
      }

      const considerOptional = optional && this.enableOptionalItems;

      if (this.client && this.client.country != 1) {
        withTax = false;
      }

      return InvoicePriceCalculator.calculateSum(this.data.products, this.data.discount, withTax, considerOptional, true, withMaxAmount);
    },
    sumTotalWithoutDiscount(optional = false, withTax = false) {
      if (this.data.products.length === 0) {
        return 0;
      }

      const considerOptional = optional && this.enableOptionalItems;

      return InvoicePriceCalculator.calculateSum(this.data.products, this.data.discount, withTax, considerOptional, false);
    },
    sumTotal(optional = false, withTax = false, withMaxAmount = false) {

      if (!this.enableOptionalItems || optional !== true) {
        return PriceHelper.floatPointToComma(this.sum(false, withTax, withMaxAmount));
      }

      const sumWithOptional = this.sum(true, withTax, withMaxAmount);
      //const sumWithoutOptional = this.sum(false, withTax);

      return PriceHelper.floatPointToComma(sumWithOptional);
    },

    sumTotalTax(optional = false) {
      return PriceHelper.floatPointToComma(this.sum(optional, true));
    },
    sumDifference(optional = false) {
      const difference = this.sum(optional, true) - this.sum(optional);
      return PriceHelper.floatPointToComma(difference);
    },
    discountSum(optional = false) {
      if (this.data.products.length === 0) {
        return 0;
      }

      const discountedSum = this.sum(optional);
      const sum = this.sumTotalWithoutDiscount(optional);

      return PriceHelper.floatPointToComma(sum - discountedSum);
    },

    calcSubTotal(index) {
      if (this.data.products.length === 0) {
        return 0;
      }

      return InvoicePriceCalculator.calculateSumWithLimit(this.data.products, index, false);
    },
    sumTotalManDays(optional = false, withAmountMax = false) {
      if (this.data.products.length === 0) {
        return 0;
      }
      const considerOptional = optional && this.enableOptionalItems;
      const totalManDays = InvoicePriceCalculator.calculateTotalManDays(this.data.products, considerOptional, withAmountMax);

      return PriceHelper.floatPointToComma(totalManDays) ;
    },
    resetData() {
      if(this.order !== null && Object.keys(this.order).length !== 0) {
        this.customPriceService.fetchCustomerPrices(this.order.client_id);
      }

      if (this.order !== null && Object.keys(this.order).length !== 0 && (this.isCancellation || this.isOrderConfirmation || this.isOfferBilling)) {

        this.data = {
          header_text: this.orderType.header_text,
          footer_text: this.orderType.footer_text,
          adjusted_invoice_sum_netto: null,
          offer_valid_to: null,
          title: this.orderType.title,
          order_date: moment().format('YYYY-MM-DD'),
          discount: this.order.discount,
          invoice_number: null,
          products: this.getOrderItems()
        };
        if(this.isOrderConfirmation) {
          this.fetchOrderNumber();
        }
      } else if (this.order !== null && Object.keys(this.order).length !== 0) {

        if(!this.order.order_date) {
          this.order.order_date = moment().format('YYYY-MM-DD')
        }


        this.data = {
          header_text: this.order.header_text,
          footer_text: this.order.footer_text,
          adjusted_invoice_sum_netto: null,
          offer_valid_to: null,
          title: this.order.title,
          invoice_number: this.order.invoice_number,
          order_date: this.order.order_date,
          discount: this.order.discount,
          products: this.getOrderItems()
        };
      } else {
        this.data = {
          header_text: this.orderType.header_text,
          footer_text: this.orderType.footer_text,
          adjusted_invoice_sum_netto: null,
          title: this.orderType.title,
          offer_valid_to: null,
          order_date: moment().format('YYYY-MM-DD'),
          invoice_number: null,
          discount: 0,
          products: []
        };
      }
    },
      fetchContractConditions() {
          ApiService.get('contract_condition')
              .then((response) => {
                  if (response.data.status === 'success') {
                      this.contractConditions = response.data.result;
                  }
              })
      },
    saveDraft() {
      const status = this.statusOptions.find(item => item.is_draft === 1 && item.default);
      this.orderStatus = status.id;

      this.saveInvoice();
    },
      formatDate(value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
      },
    saveOpenAndSendMail({emails, content, attachments, from, cc, bcc, subject, personal}) {
      this.sendMailData = {
        emails: emails,
        content: content,
        mail_template_id: this.orderType.mail_template_id,
        additional_attachments: attachments,
        from: from,
        cc: cc,
        bcc: bcc,
        subject: subject,
        personal: (personal ? 1 : 0)
      };

      this.saveOpen();
    },
    saveOpen() {
      if(!this.isCancellation) {
        const status = this.statusOptions.find(item => item.is_draft !== 1 && item.default);
        this.orderStatus = status.id;
      }

      this.saveInvoice();
    },
    saveWithStatus() {
      this.orderStatus = parseInt(this.status);
      this.saveInvoice();
    },
    saveInvoice(generatePdf = true) {
      let status = this.statusOptions.find(item => item.id === this.orderStatus);

      if(!status || status === undefined) {
        status = this.statusOptions.find(item => item.id === this.status);
      }

      if(!status || status === undefined) {
        status = this.statusOptions.find(item => item.id === parseInt(this.order.status));
      }

      if (this.edit) {

        this.$vs.loading.close();


        ApiService.put(`orders/${this.order.id}`, this.getPostData(generatePdf)).then((response) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Erfolgreich bearbeitet',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })

          if (status.is_draft) {
            this.$forceUpdate()
            return;
          }

          let orderId = response.data.result.id;
          this.$router.push({name: this.$route.meta.parent + '-view', params: { id: orderId }});
          return;

        }).catch((response) => {

          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Fehler',
            text: response.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })

        });


        return;
      }

      ApiService.post('orders', this.getPostData()).then((response) => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Erfolgreich',
          text: 'Erfolgreich angelegt',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })

        let orderId = response.data.result.id;
        this.$router.push({name: this.$route.meta.parent + '-view', params: { id: orderId }});

      }).catch((response) => {

        this.$vs.loading.close();

        this.$vs.notify({
          title: 'Fehler',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      });
    },
    saveInvoiceWithoutPDFGenerate() {
     this.saveInvoice(false)
    },
    getPostData(generatePdf) {
      let postData = {},
          currentStatus = null;


      postData.workspace_id = this.workspace.id;
      postData.client_id = this.client.id;
      postData.overdue_date = moment(this.order.overdue_date).format('YYYY-MM-DD');
      postData.order_date = moment(this.data.order_date).format('YYYY-MM-DD');
      postData.notice = this.order.notice;
      postData.status = this.status;
      postData.generate_pdf = true;
      postData.is_b2b_sellers = this.order.is_b2b_sellers;
      postData.show_accept_signature = this.order.show_accept_signature;
      postData.offer_valid_to = this.order.offer_valid_to;
      postData.alternativ_type_name = this.order.alternativ_type_name;

      postData.sum_max_budget = this.order.sum_max_budget;


      if (generatePdf === false) {
        postData.generate_pdf = false;
      }

      if (this.orderStatus !== null && !this.isCancellation) {
        postData.status = this.orderStatus;
        currentStatus = this.statusOptions.find(item => item.id === this.orderStatus);
      } else {
        currentStatus = this.statusOptions.find(item => item.id === this.status);
      }

      postData.chance = 1;
      postData.sum = PriceHelper.parseCommaFloat(this.sumTotal(false, true));
      postData.sum_netto = PriceHelper.parseCommaFloat(this.sumTotal());
      postData.adjusted_invoice_sum_netto = PriceHelper.parseCommaFloat(this.order.adjusted_invoice_sum_netto);
      postData.offer_valid_to = this.order.offer_valid_to ? moment(this.order.offer_valid_to).format('YYYY-MM-DD') : null;
      postData.sum_optional = PriceHelper.parseCommaFloat(this.sumTotal(true));
      postData.discount_sum_netto = PriceHelper.parseCommaFloat(this.discountSum());
      postData.title = this.data.title;
      postData.header_text = this.data.header_text;
      postData.footer_text = this.data.footer_text;
      postData.invoice_number = this.data.invoice_number;
      postData.order_type_id = this.typeId; // Rechnung

      if (this.selectedEmployee) {
        postData.employee_id = this.selectedEmployee.id;
      } else {
        postData.employee_id = this.userId;
      }

      if (this.selectedOfferBillingType) {
        postData.offer_billing_type_id = this.selectedOfferBillingType.id;
      } else {
        postData.offer_billing_type_id = null;
      }

      if (this.selectedSeparateInvoice) {
        postData.separately_invoice_id = this.selectedSeparateInvoice.id;
      } else {
        postData.separately_invoice_id = null;
      }

      postData.tags = this.order.tags;
      postData.categories = this.order.categories;
      postData.discount = this.data.discount ? this.data.discount : 0;
      if(this.order.period_start) {
        postData.period_start = moment(this.order.period_start).format('YYYY-MM-DD');
      } else {
        postData.period_start = null;
      }

      if(this.order.period_end) {
        postData.period_end = moment(this.order.period_end).format('YYYY-MM-DD');
      } else {
        postData.period_end = null;
      }

      postData.contact_id = this.order.contact_id;

      if (this.isOrderConfirmation) {
        postData.referrer_id = this.order.id;
      }

      if (this.isOfferBilling) {
        postData.referrer_id = this.order.id;
      }

      if (this.isCancellation) {
        postData.referrer_id = this.order.id;
      }


       postData.items = this.getPostDataItems();

      return postData;
    },
    getPostDataItems() {
      let postDataItems = [];

      let i = 0;

      for (let item of this.data.products) {
        if (item.type === 'article') {
          postDataItems.push(this.parseArticleItemPostData(item, i));
        } else if (item.type === 'break') {
          postDataItems.push({
            type: 'break',
            position: i
          });
        } else if (item.type === 'heading') {
          postDataItems.push({
            type: 'heading',
            product_name: item.name,
            position: i
          });
        } else if (item.type === 'sub_total') {
          postDataItems.push({
            type: 'sub_total',
            short_description: item.description,
            position: i,
            sum_price_netto: this.calcSubTotal(i)
          });
        } else if (item.type === 'group') {
          postDataItems.push(this.parseGroupItemPostData(item, i));

          for (let z = 0; z < item.group_products.length; z++) {
            postDataItems.push(this.parseArticleItemPostData(item.group_products[z], z, i));
          }
        }

        i++;
      }


      return postDataItems;
    },
    parseArticleItemPostData(item, i, grouped_by = null) {
      const unit = staticOptions.units.find(unit => unit.value === parseInt(item.volume_unit));

      let multiplicator = 1;

      if (unit !== undefined) {
        if (item.is_hour_unit && unit.hour_unit) {
          multiplicator = unit.multiplicator;
        }
      }

      let isOptional = item.optional;

      if(!this.enableOptionalItems) {
        isOptional = 0;
      }
      var ek_price = null;
      if(PriceHelper.parseCommaFloat(item.ek_price)) {
        ek_price = PriceHelper.parseCommaFloat(item.ek_price);
      }

      if (this.client && this.client.country != 1) {
        item.tax = 0;
      }

      return {
        id: item.item_id,
        amount: item.amount,
        amount_max: item.amount_max,
        type: 'article',
        volume_unit: parseInt(item.volume_unit),
        product_name: item.name,
        short_description: item.description,
        internal_comment: item.internal_description,
        contract_condition_id: item.contract_condition_id,
        article_assignment_id: item.id,
        vat_percent: PriceHelper.parseCommaFloat(item.tax),
        unit_price_netto: PriceHelper.parseCommaFloat(item.price) * multiplicator,
        ek_price: ek_price,
        sum_price_netto: parseFloat(item.amount) * PriceHelper.parseCommaFloat(item.price) * multiplicator,
        optional_item: isOptional,
        discount: item.discount === null ||  item.discount === undefined ? 0 : PriceHelper.parseCommaFloat(item.discount),
        is_hour_unit: item.is_hour_unit,
        grouped_by: grouped_by,
        unit_multiplicator: multiplicator,
        position: i
      };
    },
    parseGroupItemPostData(item, i) {
      const unit = staticOptions.units.find(unit => unit.value === parseInt(item.volume_unit));

      let multiplicator = 1;

      if (unit !== undefined) {
        if (item.is_hour_unit && unit.hour_unit) {
          multiplicator = unit.multiplicator;
        }
      }

      if (this.client && this.client.country != 1) {
        item.tax = 0;
      }

      return {
        id: item.item_id,
        amount: item.amount,
        type: 'group',
        volume_unit: parseInt(item.volume_unit),
        product_name: item.name,
        short_description: item.description,
        unit_price_netto: PriceHelper.parseCommaFloat(item.price) * multiplicator,
        sum_price_netto: parseFloat(item.amount) * PriceHelper.parseCommaFloat(item.price) * multiplicator,
        discount: item.discount === null? 0 : item.discount,
        is_hour_unit: item.is_hour_unit,
        unit_multiplicator: multiplicator,
        vat_percent: PriceHelper.parseCommaFloat(item.tax),
        position: i
      };
    },
    getOrderItems() {
      let orderItems = [];

      if(this.order.items === undefined) {
        return orderItems;
      }

      let multiplicator = 1;

      for (let item of this.order.items) {

        if(item.unit_multiplicator !== null ) {
          multiplicator = item.unit_multiplicator;
        }

        let price = item.unit_price_netto / parseFloat(multiplicator);

        let ek_price = null;
        if(item.ek_price) {
          ek_price = PriceHelper.floatPointToComma(parseFloat(item.ek_price));
        }

        let sum_netto = parseFloat(item.sum_price_netto) / parseFloat(multiplicator);

        if (this.isCancellation) {
          price = -price;
          sum_netto = -sum_netto;
        }

        if (this.client && this.client.country != 1) {
          item.vat_percent = 0;
        }

        if (item.type === 'article' && item.grouped_by === null) {
          orderItems.push({
            item_id: item.id,
            amount: item.amount,
            amount_max: item.amount_max,
            min_amount: item.article.min_amount,
            amount_steps: item.article.amount_steps,
            volume_unit: parseInt(item.volume_unit),
            name: item.product_name,
            description: item.short_description,
            internal_description: item.internal_comment,
            id: item.article_assignment_id,
            price: PriceHelper.floatPointToComma(price),
            ek_price:ek_price,
            tax: item.vat_percent,
            contract_condition_id: item.contract_condition_id,
            contract_condition: item.contract_condition,
            sum_price_netto: PriceHelper.floatPointToComma(sum_netto),
            optional: this.enableOptionalItems? item.optional_item : 0,
            min_price: item.article.min_price,
            ordernumber: item.article.ordernumber,
            discount: item.discount === 0 ? null : item.discount,
            discountable: item.article.discountable,
            is_hour_unit: item.article.is_hour_unit,
            customer_price: item.article.customer_price,
            article: item.article,
            type: 'article',
          });
        } else if (item.type === 'break') {
          orderItems.push({
            item_id: item.id,
            type: 'break'
          });
        } else if (item.type === 'heading') {
          orderItems.push({
            item_id: item.id,
            type: 'heading',
            name: item.product_name,
          });
        } else if (item.type === 'sub_total') {
          orderItems.push({
            item_id: item.id,
            type: 'sub_total',
            description: item.short_description
          });
        } else if (item.type === 'group') {
          orderItems.push({
            item_id: item.id,
            amount: item.amount,
            volume_unit: parseInt(item.volume_unit),
            name: item.product_name,
            description: item.short_description,
            price: PriceHelper.floatPointToComma(price),
            sum_price_netto: PriceHelper.floatPointToComma(sum_netto),
            discount: item.discount === 0 ? null : item.discount,
            discountable: true,
            is_hour_unit: item.is_hour_unit,
            tax: item.vat_percent,
            optional: this.enableOptionalItems? item.optional_item : 0,
            type: 'group',
            group_products: this.getOrderGroupArticles(item.position)
          });
        }
      }
      return orderItems;
    },
    getOrderGroupArticles(index) {
      const groupItems = [];

      let multiplicator = 1;

      for (let item of this.order.items) {

        if(item.unit_multiplicator !== null ) {
          multiplicator = item.unit_multiplicator;
        }

        let price = item.unit_price_netto / parseFloat(multiplicator);
        let sum_netto = parseFloat(item.sum_price_netto) / parseFloat(multiplicator);

        if (this.isCancellation) {
          price = -price;
          sum_netto = -sum_netto;
        }

        if (this.client && this.client.country != 1) {
          item.vat_percent = 0;
        }

        if (item.type === 'article' && item.grouped_by === index) {
          groupItems.push({
            item_id: item.id,
            amount: item.amount,
            volume_unit: parseInt(item.volume_unit),
            name: item.product_name,
            description: item.short_description,
            internal_description: item.internal_comment,
            id: item.article_assignment_id,
            tax: item.vat_percent,
            price: PriceHelper.floatPointToComma(price),
            sum_price_netto: PriceHelper.floatPointToComma(sum_netto),
            adjusted_invoice_sum_netto: PriceHelper.floatPointToComma(adjusted_invoice_sum_netto),
            optional: this.enableOptionalItems? item.optional_item : 0,
            min_price: item.article.min_price,
            ordernumber: item.article.ordernumber,
            discount: item.discount === 0 ? null : item.discount,
            discountable: item.article.discountable,
            is_hour_unit: item.article.is_hour_unit,
            grouped_by: index,
            group_index: index,
            is_grouped: true,
            type: 'article',
          });
        }
      }

      return groupItems;
    },
    fetchOrderNumber(force = false) {
      if (this.data.invoice_number && !force) {
        return;
      }

      this.$vs.loading();


      ApiService.get('order_counters?filter[0][field]=order_type_id&filter[0][expression]=exact&filter[0][value]=' + this.orderType.id).then((response) => {
        this.$vs.loading.close();
        const count = response.data.result.result[0].count;
        this.data.invoice_number = OrderHelper.generateOrderNumber(this.typeId, count);
      });
    },
    updateProduct({product, index}) {

      product.type = 'article';
      if(product.purchase_price) {
        product.ek_price = product.purchase_price;
      }

      const customPrice = this.customPriceService.getPriceByArticleId(product.id);

      if (index === null) {

        if (customPrice !== null) {
          product.price = PriceHelper.floatPointToComma(customPrice.price);
        }

        if (product.is_grouped) {
          if (this.data.products[product.group_index].group_products === undefined) {
            this.data.products[product.group_index].group_products = [];
          }
          this.data.products[product.group_index].group_products.push(product);

          return this.data.products.push();
        } else {
          this.data.products.push(product);
          return;
        }
      }

      if (product.is_grouped) {
        if (this.data.products[product.group_index].group_products[index].id !== product && customPrice !== null) {
          product.price = PriceHelper.floatPointToComma(customPrice.price);
        }

        this.data.products[product.group_index].group_products[index] = product;
        this.data.products[product.group_index].group_products.push();
      } else {
        if (this.data.products[index].id !== product && customPrice !== null) {
          product.price = PriceHelper.floatPointToComma(customPrice.price);
        }

        this.data.products[index] = JSON.parse(JSON.stringify(product));
      }


      return this.data.products.push();
    },
    editProduct(index) {
      this.visibleGroupIndex = null;
      this.addProductToGroup = false;
      this.selectedProductIndex = index;
      this.productModalActive = true;
    },
    editGroupProduct({groupIndex, index}) {
      this.visibleGroupIndex = groupIndex;
      this.addProductToGroup = true;
      this.selectedProductIndex = index;
      this.productModalActive = true;
    },
    openProductDetail(id) {
      this.selectedProductId = id;
      this.productDetailModalOpen = true;
    },
    openProductChangeModal(item) {
      this.selectedChangedProductItem = item;
      this.productChangeModalOpen = true;
    },
    addProduct() {
      this.addProductToGroup = false;
      this.selectedProductIndex = null;
      this.productModalActive = true;
    },
    addHeading() {
      this.data.products.push({
        type: 'heading',
        name: null,
      });
    },
    addGroup() {
      this.data.products.push({
        type: 'group',
        name: 'Artikel Gruppe',
        group_products: [],
        price: 0,
        price_optional: 0,
        volume_unit: 1,
        tax: 19,
        description: null,
        amount: 1,
        is_hour_unit: false,
        discount: 0,
        discountable: true
      });
    },
    addGroupProduct(index) {
      this.visibleGroupIndex = index;
      this.addProductToGroup = true;
      this.selectedProductIndex = null;
      this.productModalActive = true;
    },
    showGroupProducts(index) {
      this.visibleGroupIndex = index;
    },
    editGroup(index) {
      this.visibleGroupIndex = index;
    },
    addSubTotal() {
      this.data.products.push({
        type: 'sub_total',
        name: null,
      });
    },
    addBreak() {
      this.data.products.push({
        type: 'break',
        name: null,
      });
    },
    moveProductItemToNewIndex(oldIndex, newIndex) {
      let groupIndex = oldIndex + 1;

      if (oldIndex > newIndex) {
        groupIndex = oldIndex - 1;
      }

      this.data.products.splice(newIndex, 0, this.data.products.splice(oldIndex, 1)[0]);
      this.data.products.push();
    },
    getCountryNameById(id) {
      const country = staticOptions.countries.find(item => item.value === parseInt(id));
      if (country) {
        return country.label
      } else {
        return "unbekannt"
      }
    },

    moveProduct({direction, index}) {
      let newIndex = index + direction;

      /*if (direction !== 1 && direction !== -1) {
          return;
      }*/

      if (newIndex < 0 || newIndex > this.data.products.length - 1) {
        return;
      }

      this.moveProductItemToNewIndex(index, newIndex);
    },
    fetchEnglishOrderType(){

      ApiService.get(`order_types?filter[id]=`+this.orderType.id+`&language=en`).then((response) => {

        if (response.data.result && response.data.result.result[0]) {

          this.data.header_text = response.data.result.result[0]["header_text"];
          this.data.footer_text = response.data.result.result[0]["footer_text"];

          this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Erfolgreich auf EN geändert',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        } else {
          this.$vs.notify({
            title: 'Fehler',
            text: "Hat nicht funktioniert.",
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }

      })
    }
  },

  watch: {
    orderType(value) {
      this.data.header_text = value.header_text;
      this.data.footer_text = value.footer_text;
      this.data.title = value.title;
      //this.fetchOrderNumber(true);
    },
    client(data, oldData) {
      if(data !== null && data.id) {
        this.customPriceService.fetchCustomerPrices(this.order.client_id);
      }
      if (oldData === null) {
        this.data.header_text = this.orderType.header_text
        this.data.footer_text = this.orderType.footer_text
      }
    }
  }
}
</script>

<style scoped>
.btn.feather-icon {
  cursor: pointer;
}

.btn.feather-icon:hover {
  color: #e24733;
}
.small-alert div {
  font-size: 9px!important;
  padding-left: 30px!important;
}
.vs-input.small-input {
  width: 85%!important;
  float: left;
  margin-left: 5px;
  top: -8px;
}

.offer-valid-to {
    color: #9a5403;
    font-size: larger;
    font-weight: 600;
}
</style>