<template>
    <div class="product-group-list bg-blue-100">
        <div class="invoice-table--row row-group flex-wrap border-none bg-white">
            <div class="invoice-table--col col-center w-1/16">
                <div class="col-position">
                    <div class="invoice-table--row-position">
                        <feather-icon icon="ChevronUpIcon" svgClasses="position-up"
                                      @click="moveProduct(-1)"/>
                        <feather-icon icon="ChevronDownIcon" svgClasses="position-down"
                                      @click="moveProduct(1)"/>
                    </div>
                    {{index+1}}
                </div>
            </div>


            <div class="flex-1 invoice-table--col">
                <vs-input v-model="product.name" size="small" class="w-full"></vs-input>
                <vue-editor :editorToolbar="toolbar" v-model="product.description" class="mt-2 mb-2 is--small"></vue-editor>

                <vs-button size="small" @click="onAddGroupProduct">Produkt hinzufügen</vs-button>
                <vs-button size="small" type="border" class="ml-2" @click="onShowGroupProducts" v-if="product.group_products.length > 0 && !productsVisible">
                    Produkte anzeigen ({{product.group_products.length}})
                </vs-button>
                <vs-button size="small" type="border" class="ml-2" @click="onShowGroupProducts" v-if="product.group_products.length > 0 && productsVisible">
                    Produkte ausblenden ({{product.group_products.length}})
                </vs-button>
            </div>

            <div class="w-1/7 invoice-table--col">
                <input type="number" class="w-full vs-input--input normal" step="0.5"
                       v-model.number="product.amount" readonly/>

                <v-select
                        v-model="product.volume_unit"
                        :options="unitOptions"
                        :reduce="item => item.value"
                        :clearable="false"
                        class="mt-2">

                </v-select>
            </div>

            <div class="w-1/8 invoice-table--col">
                <p class="text-sm" v-if="product.is_hour_unit">Stundensatz:</p>
                <vs-input class="w-full mb-4" v-model="product.price" readonly />
            </div>

            <div class="w-1/8 invoice-table--col pt-5">
                <vs-input class="w-full mb-4" v-model="product.discount" placeholder="Rabatt in %"/>
                <b v-if="!product.discount">{{calculateGroupSum()}} €</b>
                <span v-if="product.discount" style="text-decoration: line-through; color: red;">{{calculateGroupSum()}} €</span>
                <b v-if="product.discount"> {{calculateDiscountedGroupSum()}} € </b>
            </div>

            <div class="w-1/12 invoice-table--col flex justify-center" v-if="enableOptional">
                <vs-checkbox v-model="product.optional" @change="onChangeOptional"></vs-checkbox>
            </div>

            <div class="invoice-table--row-delete" @click="removeProduct()">
                <feather-icon icon="DeleteIcon" class="w-6 h-6"/>
            </div>
        </div>

        <template v-for="(groupedProduct, groupedProductIndex) in product.group_products">
            <row-product :index="groupedProductIndex"
                         :product="groupedProduct"
                         :products="product.group_products"
                         @edit-row="onEditProduct"
                         @product-details-open="onOpenProductDetail"
                         @product-change-open="onOpenProductChange"
                         :is-grouped="true"
                         :enable-optional="enableOptional"
                         v-show="productsVisible">

            </row-product>
        </template>
    </div>
</template>

<script>
    import PriceHelper from "../../mixins/helper/price.helper";
    import staticOptions from "../../mixins/static/options";
    import vSelect from 'vue-select'
    import Row from "../../plugins/vue-bootstrap4-table-master/src/components/Row";
    import RowProduct from "./RowProduct";

    export default {
        components: {
            RowProduct,
            Row,
            'v-select': vSelect,
        },
        props: {
            index: {
                type: Number
            },
            products: {
                type: Array
            },
            product: {
                type: Object
            },
            enableOptional: {
                type: Boolean,
                default: false
            },
        },
        name: "RowGroup",
        data() {
            return {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'color': []}],
                ],
                productsVisible: false,
                group: {}
            }
        },
        computed: {
            taxOptions() {
                return staticOptions.tax;
            },
            unitOptions() {
                if (!this.hasOnlySameHourUnitPrices || this.product.group_products.length === 0) {
                    return staticOptions.units.filter(item => item.value === 1);
                }

                return staticOptions.units.filter(item => item.hour_unit || item.value === 1);
            },
            hasOnlyHourUnitProducts() {
                let result = this.product.group_products.find(item => !item.is_hour_unit);

                return result === null || result === undefined;
            },
            hasOnlySameHourUnitPrices() {
                if(!this.hasOnlyHourUnitProducts) {
                    return false;
                }

                let firstPrice = null,
                    samePrices = true;

                for(const [index, product] of this.product.group_products.entries()) {
                    if(index === 0) {
                        firstPrice = PriceHelper.parseCommaFloat(product.price);
                        continue;
                    }

                    if(PriceHelper.parseCommaFloat(product.price) !== firstPrice) {
                        samePrices = false;
                        break;
                    }
                }

                return samePrices;

            },
            currentGroupUnit() {
                return staticOptions.units.find(unit => unit.value === parseInt(this.product.volume_unit));
            }
        },
        methods: {
            moveProduct(direction) {
                this.$emit('move', {direction: direction, index: this.index});
            },
            removeProduct() {
                this.products.splice(this.index, 1);
            },
            onAddGroupProduct() {
                this.productsVisible = true
                this.$emit('add-product', this.index);
            },
            onShowGroupProducts() {
                this.productsVisible = !this.productsVisible;
                //this.$emit('show-products', this.index);
            },
            onClickEditGroup() {
                this.$emit('edit-group', this.index);
            },
            onEditProduct(index) {
                this.$emit('edit-product', {groupIndex: this.index, index: index});
            },
            onOpenProductDetail(id) {
                this.$emit('product-details-open', id);
            },
            onOpenProductChange(item) {
                this.$emit('product-change-open', item);
            },
            calculateGroupPrice(onlyOptional = false) {
                if (this.product.group_products === undefined || this.product.group_products.length === 0) {
                    return 0;
                }

                const sum = this.product.group_products.reduce(
                    (acc, item) => {

                        if(onlyOptional && !item.optional) {
                            return  acc;
                        }

                        let itemSum = PriceHelper.parseCommaFloat(item.price) * item.amount;

                        const unit = staticOptions.units.find(unit => unit.value === parseInt(item.volume_unit));

                        if (item.is_hour_unit) {
                            itemSum = itemSum * PriceHelper.parseCommaFloat(unit.multiplicator);
                        }

                        if (item.discount && item.discountable) {
                            const discount = PriceHelper.parseCommaFloat(item.discount);
                            return acc + (itemSum - (itemSum * discount / 100));
                        }

                        return  acc + itemSum;
                    }, 0
                );

                if(!this.currentGroupUnit.hour_unit) {
                    return sum;
                }

                const productAmounts = this.product.group_products.reduce(
                    (acc, item) => {
                        if(onlyOptional && !item.optional) {
                            return  acc;
                        }
                        const unit = staticOptions.units.find(unit => unit.value === parseInt(item.volume_unit));
                        return  acc + item.amount * PriceHelper.parseCommaFloat(unit.multiplicator);
                    }, 0
                );

                return parseFloat(sum / productAmounts.toFixed(0));
            },
            calculateGroupSum() {
                if (this.product.group_products === undefined || this.product.group_products.length === 0) {
                    return 0;
                }

                if(!this.currentGroupUnit.hour_unit) {
                    return this.calculateGroupPrice();
                }

                return PriceHelper.floatPointToComma(this.calculateGroupPrice() * this.product.amount * this.currentGroupUnit.multiplicator);
            },
            calculateDiscountedGroupSum() {
                let sum = this.calculateGroupPrice();

                const discount = this.product.discount;

                if(this.currentGroupUnit.hour_unit) {
                    sum = this.calculateGroupPrice() * this.product.amount * this.currentGroupUnit.multiplicator;
                }

                return PriceHelper.floatPointToComma(sum - (sum * discount / 100));
            },
            setGroupUnit() {
                const currentUnitAvailable = this.unitOptions.find(item => item.value === parseInt(this.product.volume_unit));

                if(currentUnitAvailable) {
                    return;
                }

                if(this.hasOnlySameHourUnitPrices) {
                    const unit = this.unitOptions.find(item => item.hour_unit);

                    return this.product.volume_unit = unit.value;
                }

                return this.product.volume_unit = 1;
            },
            setGroupAmount(onlyOptional = false) {
                if(this.hasOnlySameHourUnitPrices && this.currentGroupUnit.hour_unit) {
                    this.product.is_hour_unit = true;
                } else {
                    this.product.is_hour_unit = false;
                }

                if(!this.hasOnlySameHourUnitPrices || !this.currentGroupUnit.hour_unit) {
                    return this.product.amount = 1;
                }

                if (this.product.group_products === undefined || this.product.group_products.length === 0) {
                    return this.product.amount = 1;
                }

                const amount = this.product.group_products.reduce(
                    (acc, item) => {
                        if(onlyOptional && !item.optional) {
                            return  acc;
                        }

                        const unit = staticOptions.units.find(unit => unit.value === parseInt(item.volume_unit));

                        let itemAmount = item.amount * PriceHelper.parseCommaFloat(unit.multiplicator);

                        return  acc + itemAmount;
                    }, 0
                );

                return this.product.amount = amount.toFixed(2) / PriceHelper.parseCommaFloat(this.currentGroupUnit.multiplicator);
            },
            updateProductProperties()
            {
                this.setGroupUnit();
                this.setGroupAmount();
                const priceAll = this.calculateGroupPrice();
                const priceOptional = this.calculateGroupPrice(true);

                this.product.price = PriceHelper.floatPointToComma(priceAll - priceOptional);
                this.product.price_optional = PriceHelper.floatPointToComma(priceAll);
            },
            onChangeOptional() {
                if(this.product.group_products === null || this.product.group_products.length === 0) {
                    return;
                }

                if(this.product.optional) {
                    this.product.group_products.forEach(product => {
                       product.optional = true;
                    })
                } else {
                    this.product.group_products.forEach(product => {
                        product.optional = false;
                    })
                }
            }
        },
        watch: {
            'product.group_products': {
              deep: true,
              handler: function() {
                  this.updateProductProperties();
              }
          },
            'product.volume_unit': {
                handler: function() {
                    this.updateProductProperties();
                }
            }
        }
    }
</script>

<style scoped>

</style>