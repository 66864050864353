<template>
  <div class="invoice-page flex flex-wrap">

    <invoice v-if="fetched" :client="selectedClient" :status="invoiceStatus" :status-options="order_status"
             :isCancellation="isCancellation"
             :isOfferBilling="isOfferBilling" :isOrderConfirmation="isOrderConfirmation"
             :order="order" :edit="invoiceEdit" :type-id="order_type.id" :orderType="order_type"
             :selectedEmployee="selectedEmployee" :selectedOfferBillingType="selectedOfferBillingType" :selectedSeparateInvoice="selectedSeparateInvoice" :enableOptionalItems="enableOptionalItems"></invoice>

    <div class="invoice-details w-1/4 pl-8" v-if="fetched">


      <vs-button class="mb-4 w-full" @click="clientSelectorActive=true" v-show="showCustomerSelection">Kunde auswählen
      </vs-button>

      <client-info-modal v-if="selectedClient" :clientId="selectedClient.id" class="mb-8"
                         @contact-select="onContactSelect"></client-info-modal>

      <template v-if="invoiceEdit && orderType == 'invoice'">
        <div class="text-base font-bold mb-2">Belegstatus</div>

        <vs-select v-model="invoiceStatus" class="w-full shadow-md mb-8 rounded">
          <vs-select-item :key="index" :value="item.id" :text="item.name"
                          v-for="(item, index) in statusTypes"/>
        </vs-select>
      </template>


      <template v-if="!invoiceEdit && showTypeSelection">
        <div class="text-base font-bold mb-2" v-if="orderType == 'invoice'">Belegtyp</div>
        <div class="text-base font-bold mb-2" v-if="orderType == 'offer'">Angebotstyp</div>
        <v-select
            v-model="order_type"
            :options="orderTypes"
            class="w-full shadow-md mb-8 rounded"
            label="name"
        >
        </v-select>
      </template>

      <template v-if="selectedClient">

        <div class="text-base font-bold mb-2">Zugeordneter Kunde</div>

        <div class="rounded pl-4 pr-4 pt-5 pb-5 bg-white shadow-md mb-8">
          <div class="flex mb-1">
            <div class="w-1/3 text-base font-bold">Firma:</div>
            <div class="w-2/3 text-base">{{ selectedClient.company_name }}</div>
          </div>
          <div class="flex mb-1">
            <div class="w-1/3 text-base font-bold">Plz & Ort:</div>
            <div class="w-2/3 text-base">{{ selectedClient.zip_code }} {{ selectedClient.city }}</div>
          </div>
          <div class="flex">
            <div class="w-1/3 text-base font-bold">Anschrift:</div>
            <div class="w-2/3 text-base">{{ selectedClient.address_line_1 }}
              {{ selectedClient.address_line_2 }}
            </div>
          </div>

        </div>

        <div class="text-base font-bold mb-2">Zugeordneter Ansprechpartner</div>

        <div class="rounded pl-4 pr-4 pt-5 pb-5 bg-white shadow-md mb-4 relative" v-if="selectedContact">
          <div class="absolute right-0 top-0 pt-2 pr-2">
            <feather-icon icon="XIcon" class="cursor-pointer" title="Ansprechpartner entfernen" @click="removeSelectedContact" />
          </div>

          <div class="flex mb-1">
            <div class="w-1/3 text-base font-bold">Vorname:</div>
            <div class="w-2/3 text-base">{{ selectedContact.first_name }}</div>
          </div>
          <div class="flex mb-1">
            <div class="w-1/3 text-base font-bold">Nachname:</div>
            <div class="w-2/3 text-base">{{ selectedContact.last_name }}</div>
          </div>
          <div class="flex mb-1">
            <div class="w-1/3 text-base font-bold">E-Mail Adresse:</div>
            <div class="w-2/3 text-base">{{ selectedContact.email }}</div>
          </div>
          <div class="flex mb-1">
            <div class="w-1/3 text-base font-bold">Telefon:</div>
            <div class="w-2/3 text-base">{{ selectedContact.phone }}</div>
          </div>
          <div class="flex">
            <div class="w-1/3 text-base font-bold">Position:</div>
            <div class="w-2/3 text-base">{{ selectedContact.position }}</div>
          </div>
        </div>

        <vs-alert color="#7795D1" :active="true" icon="info" class="mb-4" v-show="!selectedContact">
          Kein Ansprechpartner vorhanden.
        </vs-alert>

        <div class="text-base font-bold mb-2"
             v-if="!isDraft && !isCancellation && !isOrderConfirmation && orderType == 'invoice'">Überfällig am
        </div>

        <datepicker v-if="!isDraft && !isCancellation && !isOrderConfirmation && this.orderType !== 'offer'"
                    v-model="order.overdue_date" placeholder="Datum wählen" class="shadow-md rounded mb-8"
                    format="dd.MM.yyyy" :clearButton="true"></datepicker>

        <div class="text-base font-bold mb-2" v-if="showPerformancePeriod">Leistungszeitraum</div>

        <div class="flex w-full mb-8" v-if="showPerformancePeriod">
          <div class="w-1/2 pr-1">
            <datepicker v-model="order.period_start" placeholder="Start wählen" class="shadow-md rounded"
                        :clearButton="true"
                        format="dd.MM.yyyy"></datepicker>

            <p class="pseudo-link mt-2" @click="setPriodToCurrentMonth">Diesen Monat</p>
          </div>
          <div class="w-1/2 pl-1">
            <datepicker v-model="order.period_end" placeholder="Ende wählen" class="shadow-md rounded"
                        format="dd.MM.yyyy" :clearButton="true"></datepicker>
            <p class="pseudo-link mt-2" @click="setPriodToLastMonth">Letzten Monat</p>
          </div>
        </div>

        <div class="text-base font-bold mb-2">Interne Notiz</div>

        <vs-textarea label="Interne Notiz" v-model="order.notice" class="shadow-md bg-white mb-8"></vs-textarea>

        <div class="text-base font-bold mb-2">Zugeordnete Kategorien</div>

        <category-selector v-model="order.categories" type="order" class="mb-8 shadow-md rounded"></category-selector>

        <div class="text-base font-bold mb-2">Zugeordnete Tags</div>

        <tag-selector v-model="order.tags" type="order" class="shadow-md rounded"></tag-selector>

        <div class="text-base font-bold mt-4 mb-2" v-if="orderType == 'offer'">Interner Ansprechpartner</div>

        <user-selector :multiple="false" class="mt-1" v-model="selectedEmployee" :value="selectedEmployee"
                       v-if="orderType == 'offer'"></user-selector>

        <div class="text-base font-bold mt-4 mb-2" v-if="orderType == 'offer'">Abrechnungstyp</div>

        <offer-billing-type-selector :multiple="false" class="mt-1" v-model="selectedOfferBillingType" :value="selectedOfferBillingType"
                       v-if="orderType == 'offer'"></offer-billing-type-selector>

        <vs-checkbox v-model="order.is_b2b_sellers" class="mt-5 d-none" >B2B-Sellers Briefkopf?</vs-checkbox>
        
        <vs-checkbox v-model="order.show_accept_signature" class="mt-5 d-none" v-if="orderType == 'offer'">Angebot akzeptieren Unterschrift?</vs-checkbox>


        <div class="mt-5">
            <b>Angebot gültig bis:</b>
            <datepicker v-if="orderType == 'offer'"
                        v-model="order.offer_valid_to" placeholder="Angebot gültig bis" class="shadow-md rounded mb-8"
                        format="dd.MM.yyyy" :clearButton="true"></datepicker>
        </div>
        <div class="text-base font-bold mt-4 mt-8" v-if="orderType == 'offer'">Seperater Rechnungskreislauf:</div>
        <small v-if="orderType == 'offer'">Bitte nur auswählen, wenn dieses Angebot in einer extra Rechnung abgerechnet werden soll. </small>
        <separately-invoices-selector :multiple="false" class="mt-1" v-model="selectedSeparateInvoice" :value="selectedSeparateInvoice" :client_id="order.client_id"
                       v-if="orderType == 'offer'"></separately-invoices-selector>

        <vs-button v-if="showDeleteDraft" icon="remove_circle_outline" color="danger" class="mt-8 w-full"
                   @click="onClickDeleteOrder">Entwurf löschen
        </vs-button>

        <vs-prompt
            v-if="isDraft"
            @accept="onDeleteOrder"
            title="Entwurf löschen"
            @close="deleteOrderPrompt=false"
            acceptText="Löschen"
            cancelText="Abbrechen"
            :active.sync="deleteOrderPrompt">
          <p>Sind Sie sicher dass den Entwurf <u>endgültig</u> gelöscht werden soll</p>
        </vs-prompt>

      </template>
    </div>

    <client-selector-popup v-model="selectedClient" :is-active="clientSelectorActive"
                           @close="closeClientSelectorActive"></client-selector-popup>
  </div>
</template>

<script>
import staticOptions from "../../mixins/static/options";
import Invoice from "../../components/invoice/Invoice";
import ClientInfoModal from "../../components/client/ClientInfoModal";
import ClientSelectorPopup from "../../components/client/ClientSelectorPopup";
import Datepicker from '@sum.cumo/vue-datepicker';
import moment from 'moment';
import ApiService from "../../api";
import CategorySelector from "../search/CategorySelector";
import TagSelector from "../search/TagSelector";
import UserSelector from "../search/UserSelector";
import * as qs from "qs";
import vSelect from 'vue-select'
import OfferBillingTypeSelector from "../search/OfferBillingTypeSelector";
import SeparatelyInvoicesSelector from "@/components/search/SeparatelyInvoicesSelector";

export default {
  name: "InvoicePage",
  components: {
    SeparatelyInvoicesSelector,
    OfferBillingTypeSelector,
    TagSelector,
    CategorySelector,
    UserSelector,
    Datepicker,
    ClientSelectorPopup,
    ClientInfoModal,
    Invoice,
    'v-select': vSelect,
  },
  props: {
    orderTypeId: {
      type: Number,
      default: 0,
    },
    orderType: {
      type: String,
      default: 'invoice'
    },
    invoiceDuplication: {
      type: Boolean,
      default: false,
    },
    invoiceEdit: {
      type: Boolean,
      default: false,
    },
    invoiceCreation: {
      type: Boolean,
      default: false,
    },
    enableOptionalItems: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteOrderPrompt: false,
      clientSelectorActive: false,
      selectedClient: null,
      invoiceNotice: null,
      invoiceStatus: 2,
      order: {},
      fetched: false,
      order_status: [],
      order_type: null,
      selectedContact: null,
      selectedEmployee: null,
      selectedOfferBillingType: null,
      selectedSeparateInvoice: null,
      categories: [],
      tags: [],
      orderTypes: []
    }
  },
  created() {
    this.$vs.loading();

    const id = this.$route.params.id;

    const orderStatusPromise = ApiService.get('order_status');

    const orderTypesPromise = ApiService.get('order_types?language=de');

    if (this.invoiceEdit) {

      const orderDataPromise = ApiService.get(`orders/${id}`);

      Promise.all([this.orderStatusPromise, orderDataPromise]).then(response => {
        if (response[1].status !== 200 || response[0].status !== 200) {
          return this.$router.push('/invoices');
        }

        this.order_status = response[0].data.result;

        const order = response[1].data.result;

        this.order_type = order.type;

        this.invoiceStatus = order.status;

        this.selectedClient = order.client;

        if (!order.contact && order.client.contacts.length > 0) {
          this.selectedContact = order.client.contacts[0];
          order.contact_id = this.selectedContact.id;
        }

        if (order.contact !== null && order.contact !== undefined) {
          this.selectedContact = order.contact;
        }
        if (order.creator !== null && order.creator !== undefined) {
          this.selectedEmployee = order.creator;
        }

        if (order.offer_billing_type !== null && order.offer_billing_type !== undefined) {
          this.selectedOfferBillingType = order.offer_billing_type;
        }
        if (order.separately_invoice !== null && order.separately_invoice !== undefined) {
          this.selectedSeparateInvoice = order.separately_invoice;
        }


        this.order = order;
        this.fetched = true;

        this.$vs.loading.close()
      }).catch((response) => {
        this.$vs.loading.close();
        console.info(response);
        this.$router.push('/invoices');
      });

      return;
    }


    if (this.invoiceDuplication) {
      const orderDataPromise = ApiService.get(`orders/${id}`);

      Promise.all([this.orderStatusPromise, this.orderTypePromise, orderDataPromise]).then(response => {
        if (response[1].status !== 200 || response[0].status !== 200 || response[2].status !== 200) {
          return this.$router.push('/invoices');
        }

        const order = response[2].data.result;
        this.order_status = response[0].data.result;

        if (this.isOrderConfirmation) {
          this.order_type = response[1].data.result.result.find(item => item.invoice_type === 'offer' && item.id !== order.order_type_id);
        } else {
          this.order_type = response[1].data.result.result[0];
        }
          this.orderTypes = response[1].data.result.result

        this.invoiceStatus = this.order_status[0].id;

        if (this.isCancellation) {
          const cancellationStatus = this.order_status.find(item => {
            return item.is_default_cancelled;
          });

          this.invoiceStatus = cancellationStatus.id;
          order.status = cancellationStatus;
        }

        this.selectedClient = order.client;

        if (!order.contact && order.client.contacts.length > 0) {
          this.selectedContact = order.client.contacts[0];
          order.contact_id = this.selectedContact.id;
        }

        if (order.contact !== null && order.contact !== undefined) {
          this.selectedContact = order.contact;
        }

        this.order = order;
        this.fetched = true;

        this.$vs.loading.close()
      }).catch((response) => {
        console.info(response);
        this.$vs.loading.close();
        this.$router.push('/invoices');
      });

    }

    if (this.invoiceCreation) {
      Promise.all([this.orderStatusPromise, this.orderTypePromise]).then(response => {

        this.order_status = response[0].data.result;
        this.orderTypes = response[1].data.result.result.filter(item => !item.is_confirmation);
        this.order_type = this.orderTypes[0];
        this.fetched = true;

        this.$vs.loading.close()
      }).catch((response) => {
        console.info(response);
        this.$vs.loading.close();
        this.$router.push('/invoices');
      });
    }
  },
  computed: {
    showCustomerSelection() {
      return !this.isCancellation && !this.isOfferBilling && !this.isOrderConfirmation;
    },
    showTypeSelection() {
      return !this.isOfferBilling && !this.isOrderConfirmation;
    },
    showPerformancePeriod() {
      return !this.isCancellation && !this.isOfferBilling && !this.isOrderConfirmation;
    },
    showDeleteDraft() {
      return this.isDraft && !this.isCancellation && !this.isOfferBilling && !this.isOrderConfirmation;
    },
    statusTypes() {
      return this.order_status;
    },
    taxOptions() {
      return staticOptions.tax;
    },
    unitOptions() {
      return staticOptions.units;
    },
    isOrderConfirmation() {
      return this.$route.query.type === 'order_confirmation' || this.$route.name === 'order-confirmation-create';
    },
    isOfferBilling() {
      return this.$route.query.type === 'billing';
    },
    isCancellation() {
      return this.$route.query.type === 'cancellation';
    },
    orderTypePromise() {
      let queryParams;
      if (this.isOfferBilling) {
        queryParams = {
          language: 'de',
          filter: [
            {
              field: 'invoice_type',
              expression: 'exact',
              value: 'invoice'
            },
            {
              field: 'is_cancellation',
              expression: 'exact',
              value: 0
            }
          ]
        };
      } else {
        queryParams = {
          language: 'de',
          filter: [
            {
              field: 'invoice_type',
              expression: 'exact',
              value: this.orderType
            }
          ]
        };

        if (this.isCancellation) {
          queryParams.filter.push({
            field: 'is_cancellation',
            expression: 'exact',
            value: 1
          })
        } else {
          queryParams.filter.push({
            field: 'is_cancellation',
            expression: 'exact',
            value: 0
          })
        }
      }

      return ApiService.get('order_types', {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      });
    },
    orderStatusPromise() {
      let queryParams;
      if (this.isOfferBilling) {
        queryParams = {
          filter: [
            {
              field: 'invoice_type',
              expression: 'exact',
              value: 'invoice'
            },
          ]
        };
      } else {
        queryParams = {
          filter: [
            {
              field: 'invoice_type',
              expression: 'exact',
              value: this.orderType
            }
          ]
        };
      }

      return ApiService.get('order_status', {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      });
    },
    currentOrderStatus() {
      if (this.invoiceStatus != undefined && this.invoiceStatus != null) {
        return this.statusTypes.find(item => item.id === parseInt(this.invoiceStatus));
      }


      return null;
    },
    isDraft() {
      if (!this.currentOrderStatus) {
        return false;
      }
      return this.currentOrderStatus.is_draft;
    }
  },
  methods: {
    onContactSelect(contact) {
      this.selectedContact = contact;
      this.order.contact_id = contact.id;
    },
    setPriodToCurrentMonth() {
      this.order.period_start = moment().startOf('month').format('YYYY-MM-DD');
      this.order.period_end = moment().endOf('month').format('YYYY-MM-DD');
      this.order = Object.assign({}, this.order);
    },
    setPriodToLastMonth() {
      this.order.period_start = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.order.period_end = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      this.order = Object.assign({}, this.order);
    },
    onClickDeleteOrder() {
      this.deleteOrderPrompt = true;
    },
    onDeleteOrder() {
      this.$vs.loading();

      ApiService.delete(`orders/${this.$route.params.id}`).then(response => {
        if (response.status === 200) {
          this.$vs.loading.close();
          this.$router.push('/invoices');
        }
      });
    },
    removeSelectedContact() {
      this.selectedContact = null;
      this.order.contact_id = null;
    },
    closeClientSelectorActive(){
      this.clientSelectorActive = false;
      if (this.selectedClient && this.selectedClient.country != 1) {
        ApiService.get('order_types?language=en').then(response => {
          if(response.data.status !== 'success') {
            return;
          }

          this.options = response.data.result;
        });

      }

      /*this.orderTypes[0].header_text = "test1"
      this.orderTypes[1].header_text = "test2"
      this.orderTypes.forEach(orderType => {
        if(orderType.trans != null) {
          selectedClient.country
        }
      })


      console.log("kunde ausgewählt")*/
    }
  },
  watch: {
    selectedClient(client, oldClient) {
      if (client.payment_deadline && client.payment_deadline !== 0) {
        this.order.overdue_date = moment().add(client.payment_deadline, 'days').format('YYYY-MM-DD');
      } else {
        this.order.overdue_date = moment().add(14, 'days').format('YYYY-MM-DD');
      }

      if (oldClient === null && this.order.contact_id === null) {
        if (client.contacts) {
          return this.selectedContact = client.contacts[0]
        }
      }

      if (this.order.contact_id) {
        return this.selectedContact = this.order.contact;
      }

      this.selectedContact = null;
    }
  }
}
</script>

<style scoped>

</style>