import PriceHelper from "../../mixins/helper/price.helper";
import staticOptions from "../../mixins/static/options";

const InvoicePriceCalculator = {

    calculateSum(products, discount = 0, considerTax = false, considerOptional = false, considerDiscount = false, considerAmountMax = false) {

       discount = PriceHelper.parseCommaFloat(discount);


        return products.filter((item) => (item.type === 'article' || item.type === 'group')).reduce(
            (acc, item) => {

                if(item.optional && !considerOptional && item.type !== 'group') {
                    return acc;
                }
                let amount = considerAmountMax == false ? item.amount : (item.amount_max == null || !item.amount_max ? item.amount : item.amount_max);

                let itemSum = PriceHelper.parseCommaFloat(item.price) * PriceHelper.parseCommaFloat(amount);

                const unit = staticOptions.units.find(unitItem => unitItem.value === parseInt(item.volume_unit));

                const itemTax = (parseFloat(item.tax) / 100 + 1);

                if(item.type === 'group' && considerOptional) {
                    itemSum = PriceHelper.parseCommaFloat(item.price_optional) * PriceHelper.parseCommaFloat(amount);
                }

                if (item.is_hour_unit && unit.hour_unit) {
                    itemSum = itemSum * PriceHelper.parseCommaFloat(unit.multiplicator);
                }

                if(considerDiscount) {
                    if (!item.discountable) {
                        if (considerTax) {
                            return acc + itemSum * itemTax;
                        }
                        return acc + itemSum;
                    }


                    if (discount !== null && discount > 0) {
                        itemSum = (itemSum - (itemSum * discount / 100));
                    }

                    if (item.discount) {
                        const discount = PriceHelper.parseCommaFloat(item.discount);

                        if (considerTax) {
                            return acc + (itemSum - (itemSum * discount / 100)) * itemTax;
                        }

                        return acc + (itemSum - (itemSum * discount / 100));
                    }
                }

                if (considerTax) {
                    return acc + itemSum * itemTax;
                }


                return acc + itemSum;
            }, 0
        );
    },

    calculateSumWithLimit(products, limit, considerOptional = false) {

        const start = this.getLastSubTotalIndex(products, limit);

        return products.filter((item, itemIndex) => (itemIndex < limit && itemIndex >= start) && (item.type === 'article' || item.type === 'group')).reduce(
            (acc, item) => {

                if(item.optional && !considerOptional && item.type !== 'group') {
                    return acc;
                }

                let itemSum = PriceHelper.parseCommaFloat(item.price) * PriceHelper.parseCommaFloat(item.amount);

                const unit = staticOptions.units.find(unit => unit.value === parseInt(item.volume_unit));

                if(item.type === 'group' && considerOptional) {
                    itemSum = PriceHelper.parseCommaFloat(item.price_optional) * PriceHelper.parseCommaFloat(item.amount);
                }

                if (item.is_hour_unit && unit.hour_unit) {
                    itemSum = itemSum * PriceHelper.parseCommaFloat(unit.multiplicator);
                }

                const discount = PriceHelper.parseCommaFloat(item.discount);

                return acc + (itemSum - (itemSum * discount / 100));
            }, 0
        );
    },

    getLastSubTotalIndex(products, limit) {
        let lastIndex = 0;

        products.forEach((product, index)  => {
            if(index < limit && product.type === 'sub_total') {
                lastIndex = index;
            }
        });

        return lastIndex;
    },

    calculateTotalManDays(products, considerOptional= false, considerMaxAmount= false){
        return products.reduce((acc, item)=> {
            const unit = staticOptions.units.find(unitItem => unitItem.value === parseInt(item.volume_unit));

            if (item.is_hour_unit && unit.hour_unit ) {
                let amount = (considerMaxAmount == true ? (item.amount_max ? item.amount_max  : item.amount) : item.amount);
               let totalHours = PriceHelper.parseCommaFloat(amount) * PriceHelper.parseCommaFloat(unit.multiplicator);
                if(item.optional && considerOptional) {
                    return  acc
                }

               // 1 MannDay = 8 hours
               const manDay = 8;

               return  acc +  (totalHours  / manDay);
            }
            return acc
        }, 0)
    }

};

export default InvoicePriceCalculator;