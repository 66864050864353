<template>
    <div class="client-info-modal">
        <slot :openClientModal="openModal">
            <vs-button @click="openModal" color="primary" type="border" class="w-full">Kunden Informationen</vs-button>
        </slot>
        <vs-popup class="client-info-modal--popup" title="Kunden Informationen" :active.sync="modalActive">
            <vs-tabs alignment="fixed" v-if="client">
                <vs-tab label="Adressdaten">
                    <div class="client-info-row">
                        <div class="col">Kundennummer</div>
                        <div class="col">{{client.customer_number}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Firma</div>
                        <div class="col">{{client.company_name}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Anschrift 1</div>
                        <div class="col">{{client.address_line_1}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Anschrift 2</div>
                        <div class="col">{{client.address_line_2}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Postleitzahl & Ort</div>
                        <div class="col">{{client.zip_code}} {{client.city}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Land</div>
                        <div class="col">{{client.country}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Telefon</div>
                        <div class="col"><a :href="`tel:${client.phone}`">{{client.phone}}</a></div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Fax</div>
                        <div class="col">{{client.fax}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">E-Mail-Adresse</div>
                        <div class="col"><a :href="`mailto:${client.email}`">{{client.email}}</a></div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Webseite</div>
                        <div class="col"><a :href="client.websites">{{client.websites}}</a></div>
                    </div>
                </vs-tab>
                <vs-tab label="Zahlungsinformationen">
                    <div class="client-info-row">
                        <div class="col">Steuernummer</div>
                        <div class="col">{{client.ust_id}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Payment Deadline</div>
                        <div class="col">{{client.payment_deadline}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">IBAN</div>
                        <div class="col">{{client.bank_iban}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">SWIFT/BIC</div>
                        <div class="col">{{client.bank_bic}}</div>
                    </div>
                    <div class="client-info-row">
                        <div class="col">Kontoinhaber</div>
                        <div class="col">{{client.bank_account_owner}}</div>
                    </div>
                </vs-tab>
                <vs-tab label="Ansprechpartner">
                    <vs-table :data="client.contacts" :hoverFlat="false">
                        <template slot="thead">
                            <vs-th>
                                Ansprechparnter/in
                            </vs-th>
                            <vs-th>
                                Position
                            </vs-th>
                            <vs-th>
                                E-Mail
                            </vs-th>
                            <vs-th>
                                Telefon
                            </vs-th>
                            <vs-th>

                            </vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                                <vs-td :data="tr.email">
                                    {{salutation(tr.salutation)}} {{tr.first_name}} {{tr.last_name}}
                                </vs-td>

                                <vs-td :data="tr.position">
                                    {{tr.position}}
                                </vs-td>

                                <vs-td :data="tr.email">
                                    <a :href="`mailto:${tr.email}`">{{tr.email}}</a>
                                </vs-td>

                                <vs-td :data="tr.phone">
                                    <a :href="`tel:${client.phone}`">{{client.phone}}</a>
                                </vs-td>

                                <vs-td>
                                    <vs-button size="small" icon="check" color="primary" type="border" @click="selectContact(tr)"></vs-button>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </vs-tab>
                <vs-tab label="Umsätze">
                    <div>

                    </div>
                </vs-tab>
            </vs-tabs>
            <div class="flex p-2 justify-end bg-gray-200 rounded-b">
                <vs-button @click="toClient">Kunde bearbeiten</vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
    import ApiService from "../../api";
    import staticOptions from "../../mixins/static/options";

    export default {
        name: "ClientInfoModal",
        props: ['clientId'],
        data() {
            return {
                modalActive: false,
                client: null
            }
        },
        mounted() {
        },
        methods: {
            toClient() {
                this.$router.push(`/customers/${this.clientId}`);
            },
            salutation(value) {
                let salutation = staticOptions.salutations.find((item) => item.value === value);

                if(salutation === null) {
                    return null;
                }

                return salutation.label;
            },
            openModal() {
                this.fetchClientData();
            },
            fetchClientData() {
                if(this.clientId == null) {
                    return;
                }

                if(this.client && this.clientId === this.client.id) {
                    this.modalActive = true;
                    return;
                }

              this.$vs.loading();

                ApiService.get('clients/' + this.clientId)
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.fetched = true;
                            this.client = response.data.result;
                            this.modalActive = true;
                        }

                        this.$vs.loading.close();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                    })
            },
            selectContact(contact) {
                this.modalActive = false;

               this.$emit('contact-select', contact);
            }
        },
        beforeDestroy() {
            let components = this.$children;

            for(let i=0; i < components.length; i++) {
                if (components[i].$el.classList && components[i].$el.classList.contains('client-info-modal--popup')) {
                    components[i].$el.remove();
                }
            }
        },
    }
</script>

<style lang="scss">
    .client-info-modal--popup {
        .vs-popup {
            min-width: 700px;
            max-width: 100vw;
        }
        .vs-popup--content {
            padding: 5px 0 0 0;
            margin: 0;
            min-height: 60px;
            width: 100%;

            .client-info-row {
                display: flex;
                border-bottom: solid 1px rgba(0,0,0,.05);
                &:last-child {
                    border-bottom: none;
                }
                &:nth-child(even) {
                    background-color: rgba(0,0,0,.02);
                }
                .col {
                    padding: 10px;
                    width: 66.66%;
                    &:first-child {
                        width: 33.33%;
                        font-weight: bold;
                        &:after {
                            content: ":";
                        }
                    }
                }
            }
        }
    }
</style>
