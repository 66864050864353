<template>
    <vs-popup id="product-modal" :title="title" :active="visible" @close="onClose" class="product-modal">
        <div id="product-modal--loader" class="vs-con-loading__container overflow-visible">
            <mg-select :options="products"
                       track-by="id"
                       label="name"
                       placeholder="Produkt auswählen"
                       v-model="productId"
                       @select="onSelectProduct"
                       @remove="selectedProduct=null">

            </mg-select>
            <template v-if="selectedProduct !== null">
                <vs-input class="mt-4 w-full mb-4" label="Produktname" v-model="selectedProduct.name"></vs-input>

                <label class="vs-input--label">Produktbeschreibung</label>
                <vue-editor :editorToolbar="toolbar" v-model="selectedProduct.description"></vue-editor>
            </template>
            <div class="flex justify-end mt-4">
                <vs-button color="danger" type="border" @click="onClose">Abbrechen</vs-button>
                <vs-button color="success" class="ml-4" @click="onUpdateProduct" :disabled="!selectedProduct">Speichern
                </vs-button>
            </div>

        </div>
    </vs-popup>
</template>

<script>
    import MgSelect from "../mg-select/MgSelect";
    import ApiService from "../../api";

    export default {
        name: "ProductModal",
        components: {MgSelect},
        props: {
            product: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            productIndex: {
                type: Number,
                default: null
            },
            active: {
                type: Boolean,
                default: false
            },
            addToGroup: {
                type: Boolean,
                default: false
            },
            groupIndex: {
                type: Number,
                default: null
            },
        },
        data() {
            return {
                products: [],
                productId: null,
                selectedProduct: null,
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'color': []}],
                ],
            }
        },
        computed: {
            visible() {
                return this.active;
            },
            title() {
                if (this.product && this.productIndex !== null) {
                    return 'Produkt bearbeiten';
                }

                return 'Produkt auswählen';
            }
        },
        methods: {
            fetchProducts() {
                this.openLoading();
                ApiService.get('articles').then(response => {

                    if (response.status !== 200) {
                        return;
                    }

                    this.products = response.data.result;

                    if(this.productId !== null && this.product === null) {
                        this.onSelectProduct(this.products.find(item => item.id = this.productId));
                    }

                    this.closeLoading();

                });
            },
            onUpdateProduct() {
                this.$emit('updated', {product: this.selectedProduct, index: this.productIndex});
                this.onClose();
            },
            onSelectProduct(product) {

                product.amount = parseInt(product.min_amount);
                product.volume_unit = parseInt(product.unit);
                product.is_grouped = this.addToGroup;
                product.optional = false;
                product.discount = 0;

                if (this.addToGroup) {
                    product.group_index = this.groupIndex
                }
                const strippedDescription = product.offer_description.replace(/(<([^>]+)>)/gi, "");
                if (this.$route.name == 'offers-detail' && strippedDescription.length > 3) {
                    product.description = product.offer_description
                }

                this.selectedProduct = JSON.parse(JSON.stringify(product));
            },
            onClose() {
                this.$emit('update:active', false)
            },
            openLoading() {
                this.$vs.loading({
                    container: '#product-modal--loader',
                    scale: 0.6
                });
            },
            closeLoading() {
                this.$vs.loading.close('#product-modal--loader > .con-vs-loading');
            },
        },
        watch: {
            active(value) {
                if (value) {
                    if (this.product === null) {
                        this.selectedProduct = null;
                        this.productId = null;
                    }
                    this.fetchProducts();
                }
            },
            product(data) {
                if (data === null) {
                    this.productId = null;
                    this.selectedProduct = null;
                    return;
                }

                this.productId = data.id;
                this.selectedProduct = JSON.parse(JSON.stringify(data));
            }
        }
    }
</script>

<style lang="scss">
    #product-modal--loader {
        .con-vs-loading {
            position: absolute;
        }
    }
</style>