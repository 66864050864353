<template>
    <div class="invoice-table--row" v-if="product" :class="{'border-none': index === (products.length - 1)}" @dblclick="onDoubleClick">

        <div class="invoice-table--col col-center w-1/16">
            <div class="col-position" v-if="!isGrouped">
                <div class="invoice-table--row-position">
                    <feather-icon icon="ChevronUpIcon" svgClasses="position-up"
                                  @click="moveProduct(-1)"/>
                    <feather-icon icon="ChevronDownIcon" svgClasses="position-down"
                                  @click="moveProduct(1)"/>
                </div>
                <template v-if="!inlineEdit">
                    {{index+1}}
                </template>
                <template v-else>
                    <vs-input size="small" v-model="indexClone" class="w-full mb-2"></vs-input>
                </template>

            </div>
        </div>

        <div class="flex-1 invoice-table--col">
            <div class="col-options">
                <div class="col-options--button" @click="onClickProductDetails" title="Produktdetails">
                    <feather-icon icon="InfoIcon" class="w-5 h-5"/>
                </div>
                <div class="col-options--button change-product-icon" @click="onClickProductChange" title="Produkt tauschen" v-if="!inlineEdit" >
                    <feather-icon icon="RefreshCcwIcon" class="w-5 h-5"/>
                </div>
                <div class="col-options--button"  v-if="product.customer_price">
                    <vx-tooltip  position="bottom">
                        <feather-icon icon="StarIcon"  class="w-5 h-5 red"/>
                        <template v-slot:text>
                            Von: {{product.customer_price.from}} <br>
                            Bis: <span v-if="product.customer_price.from != null">{{product.customer_price.to}}</span><span v-if="product.customer_price.to == null">beliebig</span><br>
                            <b>Preis: {{product.customer_price.price}}€</b>
                        </template>
                    </vx-tooltip>
                </div>
            </div>
            <template v-if="!inlineEdit">
            <span v-html="product.name"></span>
                <p class="mb-2 text-sm articleinfo"><span class="ordernumber">Nummer: {{product.ordernumber}}</span><span class="name ml-1" v-if="product.article"> {{product.article.name}}</span></p>
            <p v-html="product.description"></p>
              <span class="estimated_effort" v-if="product.amount_max != null">Geschätzter Aufwand: {{ product.amount }} - {{ product.amount_max }} {{ selectedUnit.labelShort }}.</span>
              <vs-alert color="#7795D1" :active="true" icon="info" class="mb-2 mt-1 small-alert" v-show="product.amount_max == ''" >
               Kein maximaler Aufwand hinterlegt
              </vs-alert>

                <div style="background: rgb(230 230 230);padding: 10px;font-size: 11px;" v-if="isOffer && lineItemHasContractCondition(product)">
                    <b>Vertragskondition:</b> <br>
                    {{ getContractCondition(product.contract_condition_id).document_text_de}}


                    <span v-show="defaultContractConditionisSameAsSelected(product)" >
                        <i style="color:#d78729;display: block" class="mt-2">Die Standard Vertragskondition die dem Artikeltyp zugeordnet ist, entspricht nicht dieser die du ausgewählt hast. Du kannst sie trotzdem verwenden.</i>
                    </span>
                </div>

                <vs-alert color="#df0101" :active="true" icon="warning" class="mb-2 mt-1 small-alert" v-show="isOffer && articleRequiresContractCondition(product) && !lineItemHasContractCondition(product)" >
                   Keine Vertragskondition hinterlegt!!! Bitte hinterlegen.
                </vs-alert>

            </template>
            <template v-else>
                <vs-input size="small" v-model="productClone.name" class="w-full mb-2"></vs-input>
                <vue-editor :editorToolbar="toolbar" v-model="productClone.description" class="mb-2"></vue-editor>

                <vs-alert color="#d78729" :active="true" icon="warning"  style="height: auto" class="mb-2 mt-1" v-show="isOffer && defaultContractConditionisSameAsSelected(product) && lineItemHasContractCondition(product)" >
                    <div>
                        Standard Vertragskondition:<br>
                        <small v-if="product.article && product.article.contract_condition">{{product.article.contract_condition.working_title}}</small>
                        <small v-else> // Bitte einmal speichern um die Vertragskondition einsehen zu können.</small>
                    </div>

                </vs-alert>

                <vs-select label="Vertragskondition:" v-model="product.contract_condition_id" class="w-full mt-2 small" v-show="isOffer && articleRequiresContractCondition(product)">
                   <vs-select-item :key="item.id" :value="item.id" :text="item.working_title"
                                    v-for="(item, index) in contractConditions"/>
                    <vs-select-item  :value=null text="Keine Vertragskondition ausgewählt"/>
                </vs-select>

                <div class="flex justify-end">
                    <vs-button size="small" @click="cancelInlineEdit">Abbrechen</vs-button>
                    <vs-button size="small" color="success" @click="saveInlineEdit">Speichern</vs-button>
                </div>
            </template>
        </div>

        <div class="w-1/7 invoice-table--col">
          <p class="text-sm">Menge: <span class="ek-price-btn" @click='toggleAmountMax(product)' v-if="isOffer">

            <span v-if="showAmountMax || product.amount_max != null">Einzel</span>
            <span v-else>Von-Bis</span>
          </span>
          </p>
            <div v-if="showAmountMax || product.amount_max != null" class="flex w-full">
              <div class="w-1/2 pr-1">
                <input type="number" class="w-full vs-input--input normal mr-1" step="0.5"
                       v-model.number="product.amount"  :name="`min_amount_${index}`" v-validate="'required'" data-vv-as="Menge"/>

              </div>
              <div class="minus-badge">-</div>
              <div class="w-1/2 pl-1">
                <input type="number" class="w-full vs-input--input normal" step="0.5"
                       v-model.number="product.amount_max"  :name="`max_amount_${index}`" v-validate="'required'" data-vv-as="Menge Max"/>
              </div>
            </div>
            <div v-else>
              <input type="number" class="w-full vs-input--input normal" step="0.5"
                     v-model.number="product.amount"  :name="`min_amount_${index}`" v-validate="'required'" data-vv-as="Menge"/>
            </div>

            <span class="text-danger text-sm" v-if='errors.has("min_amount_"+ index)'>{{ errors.first("min_amount_"+ index) }}</span>

            <vs-alert color="#7795D1" :active="true" icon="info" class="mb-2 mt-1 small-alert" v-show="product.min_amount > product.amount" >
                Min. Abnahme: {{product.min_amount}}
            </vs-alert>
            <vs-alert color="#7795D1" :active="true" icon="info" class="mb-2 mt-1 small-alert" v-show="product.amount_steps > 1" >
                Staffelung: {{product.amount_steps}}
            </vs-alert>


            <v-select
                      v-model="product.volume_unit"
                      :options="unitOptions"
                      :reduce="item => item.value"
                      :clearable="false"
                      class="mt-2">

            </v-select>

        </div>

        <div class="w-1/8 invoice-table--col" v-if="!product.is_hour_unit">
            <vs-input class="w-full mb-4" v-model="product.price" v-currency   :name="`price_${index}`" v-validate="'required'" data-vv-as="Einzelpreis"/>
            <span class="text-danger text-sm" v-if='errors.has("price_"+ index)'>{{ errors.first("price"+ index) }}</span>
            <div class="ek_price">
                <vs-input class="w-full mb-4" v-model="product.ek_price" v-currency  placeholder="Einkaufspreis"  :name="`ek_price_${index}`"  />
                <div class="marge" v-if="margePercent">
                    {{margePercent}}%
                </div>
            </div>

            <p v-if="product.min_price">Min: {{product.min_price}} €</p>
        </div>

        <div class="w-1/8 invoice-table--col" v-if="product.is_hour_unit">
            <p class="text-sm">Stundensatz: <span class="ek-price-btn" @click='toggleEkPrice()'>EK</span></p>
            <vs-input class="w-full mt-1 mb-1" v-model="product.price" v-currency/>
            <span class="text-danger text-sm" v-if='!product.price'>Dieses Feld muss gefüllt sein!!</span>
            <div class="ek_price" v-if="showEKPrice">
                <vs-input class="w-full mb-4" v-model="product.ek_price" v-currency  placeholder="Einkaufspreis"  :name="`ek_price_${index}`"  />
                <div class="marge" v-if="margePercent">
                    {{margePercent}}%
                </div>
            </div>
            <p>Min: {{product.min_price|currency}} €</p>
        </div>

        <div class="w-1/8 invoice-table--col" v-if="product.discountable">
            <vs-input class="w-full mb-4" v-model="product.discount" placeholder="Rabatt in %"/>
            <b v-if="!product.discount">{{calculateProductSum(false)|currency}} €</b>
            <span v-if="product.discount" style="text-decoration: line-through; color: red;">{{calculateProductSum(false)|currency}} €</span>
            <b v-if="product.discount"> {{calculateProductSum()|currency}} € </b>
        </div>

        <div class="w-1/8 invoice-table--col pt-5" v-if="!product.discountable">
            <b>{{calculateProductSum(false)|currency}}</b>
        </div>

        <div class="w-1/12 invoice-table--col flex justify-center" v-if="enableOptional">
            <vs-checkbox v-model="product.optional"></vs-checkbox>
        </div>

        <div class="invoice-table--row-delete" @click="removeProduct()">
            <feather-icon icon="DeleteIcon" class="w-6 h-6"/>
        </div>

    </div>
</template>

<script>
    import staticOptions from "../../mixins/static/options";
    import PriceHelper from "../../mixins/helper/price.helper";
    import vSelect from 'vue-select'

    export default {
        components: {
            'v-select': vSelect,
        },
        props: {
            index: {
                type: Number
            },
            products: {
                type: Array
            },
            product: {
                type: Object
            },
            enableOptional: {
                type: Boolean,
                default: false
            },
            isGrouped: {
                type: Boolean,
                default: false
            },
            contractConditions: {
                type: Array,
                default: []
            },
            order: {
                type: Object
            },
        },
        name: "RowProduct",
        created() {
            if(this.product && this.product.ek_price) {
                this.showEKPrice = true;
            }
        },
        data() {
            return {
                selected: 1,
                inlineEdit: false,
                productClone: null,
                indexClone: null,
                showEKPrice: false,
                showAmountMax: false,
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'color': []}],
                ],
            };
        },
        computed: {
            isOffer() {
                if (!this.order || !this.order.type){
                    return false;
                }
                return this.order.type.invoice_type === 'offer';
            },
            isInvoice() {
                if (!this.order || !this.order.type){
                    return false;
                }
                return this.order.type.invoice_type === 'invoice';
            },
            taxOptions() {
                return staticOptions.tax;
            },
            unitOptions() {
                if (!this.product.is_hour_unit) {
                    return staticOptions.units;
                }

                return staticOptions.units.filter(item => item.hour_unit);

            },
            selectedUnit() {
                return staticOptions.units.find(item => item.value === parseInt(this.product.volume_unit));
            },
            margePercent() {
                var marge = this.calcMargePercent();
                if (!marge || marge == 100) {
                    return
                }
                return PriceHelper.floatPointToComma(marge);
            },
        },
        methods: {
            toggleEkPrice: function(){
                this.showEKPrice = !this.showEKPrice
            },
          toggleAmountMax(product) {


              if (this.showAmountMax == true || product.amount_max != null) {
                product.amount_max = null
                this.showAmountMax = false
                return
              }

                this.showAmountMax = !this.showAmountMax
            },
            calcMargePercent() {
                let product = this.product;
                let amount = parseFloat(product.amount);

                var sum = PriceHelper.parseCommaFloat(product.price) * amount;
                var marge =  sum - (PriceHelper.parseCommaFloat(product.ek_price) * amount);

                return  (marge / sum)*100;
            },
            moveProduct(direction) {
                this.$emit('move', {direction: direction, index: this.index});
            },
            getContractCondition(id) {
                return this.contractConditions.find(item => item.id === parseInt(id));
            },
            onClickEditProduct() {
                this.$emit('edit-row', this.index);
            },
            onClickProductDetails() {
                this.$emit('product-details-open', this.product.id);
            },
            onClickProductChange() {
                this.$emit('product-change-open', this.product);
            },
            calculateProductSum(discounted = true) {
                let product = this.product;

                let amount = parseFloat(product.amount);

                let sum = PriceHelper.parseCommaFloat(product.price) * amount;

                if(product.is_hour_unit) {
                    sum = sum * PriceHelper.parseCommaFloat(this.selectedUnit.multiplicator);
                }

                if (discounted && product.discount && product.discount != 0) {
                    const discount = PriceHelper.parseCommaFloat(product.discount);

                    sum = sum - (sum * discount / 100);
                }

                return PriceHelper.parseCommaFloat(sum);
            },
            removeProduct() {
                this.$emit('removed');
                this.products.splice(this.index, 1);
            },
            onDoubleClick() {
                if(this.inlineEdit) {
                    return;
                }

                this.inlineEdit = true;
                this.productClone = JSON.parse(JSON.stringify(this.product));
                this.indexClone = JSON.parse(JSON.stringify(this.index))+1;
            },

            saveInlineEdit() {
                this.product.name = this.productClone.name;
                this.product.description = this.productClone.description;
                this.inlineEdit = false;
                this.productClone = null;
                this.indexClone = this.indexClone - 1

                if(this.indexClone < this.index) {
                    let direction = this.index - this.indexClone
                    this.$emit('move', {direction: - direction, index: this.index});
                } else if(this.indexClone > this.index) {
                    let direction = this.indexClone - this.index
                    this.$emit('move', {direction: direction, index: this.index});
                }
                this.indexClone = null
            },

            cancelInlineEdit() {
                this.inlineEdit = false;
                this.productClone = null;
            },
            articleRequiresContractCondition(product) {
                if (product.article === undefined){
                    return product.require_contract_condition
                }
                return product.article && product.article.require_contract_condition
            },
            lineItemHasContractCondition(product){
                return product && product.contract_condition_id != null
            },
            defaultContractConditionisSameAsSelected(product){
                if (product.article === undefined){
                    return product.default_contract_condition_id !== product.contract_condition_id
                }
                return product.article && product.article.default_contract_condition_id !== product.contract_condition_id
            }
        },
        filters: {
            currency(value) {
                return PriceHelper.floatPointToComma(value);
            },
        }
    }
</script>

<style scoped>
    .red.feather-icon {
        color: red;
    }

    .marge {
        position: absolute;
        top: 2px;
        right: 2px;
        font-size: 10px;
    }

    .ek_price {
        position: relative;
    }
    span.ek-price-btn {
        background: #efefef;
        padding: 2px 8px;
        font-size: 10px;
        border-radius: 8px;
        cursor: pointer;
    }
    span.ek-price-btn:hover {
        background:#cacaca;
    }
    .articleinfo .name {
        display:none;
        color: #868686;
    }
    .articleinfo:hover .name {
        display:inline
     }
    .invoice-table--row .change-product-icon {
        display: none;
    }
    .invoice-table--row:hover .change-product-icon {
        display: inline;
    }
    .minus-badge {
      position: absolute;
      z-index: 999999999;
      left: 76px;
      top: 37px;
      font-weight: 600;
    }
    span.estimated_effort {
      font-size: 85%;
      color: #8b8b8b;
    }

</style>